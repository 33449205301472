import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: ref({
      login: null as any,
      type: null as any,
      id: null as any,
      email: null as any,
      nickname: null as any,
      thumbnail: null as any,
      stealth: null as any
    }),
    find: ref({
      email: '',
      code: {
        value: '',
        expire: 0,
        count: 0
      }
    }),
    join: ref({
      email: '',
      code: {
        value: '',
        expire: 0,
        count: 0
      }
    }),
    token: reactive({
      access: {
        token: '',
        expire: 0
      },
      refresh: {
        token: '',
        expire: 0
      }
    } as any),
    experience: ref({
      game: [],
      board: []
    } as any),
    history: ref(null)
  }),

  actions: {
    // 정보 업데이트
    syncCookie () {
      let user: any = useCookie('user')
      let token: any = useCookie('token')
      let join: any = useCookie('join')
      let find: any = useCookie('find')
      let userData: any = typeof user.value === 'object' ? user.value : null
      let tokenData: any = typeof token.value === 'object' ? token.value : null
      let joinData: any = typeof join.value === 'object' ? join.value : null
      let findData: any = typeof find.value === 'object' ? find.value : null
      if (userData) {
        const user = {
          login: true,
          type: userData.type,
          id: userData.id,
          email: userData.email,
          nickname: userData.nickname,
          thumbnail: userData.thumbnail,
          stealth: userData.stealth
        }
        this.user = user
      }
      if (tokenData) {
        const token = {
          access: {
            token: tokenData.access.token,
            expire: tokenData.access.expire
          },
          refresh: {
            token: tokenData.refresh.token,
            expire: tokenData.refresh.expire
          }
        }
        this.token = token
      }
      if (joinData) {
        const join = {
          email: joinData.email,
          code: {
            value: joinData.code.value,
            expire: joinData.code.expire,
            count: joinData.code.count,
          }
        }
        this.join = join
      }
      if (findData) {
        const find = {
          email: findData.email,
          code: {
            value: findData.code.value,
            expire: findData.code.expire,
            count: findData.code.count,
          }
        }
        this.find = find
      }

      // 메모리 제거
      user = null
      token = null
      join = null
      find = null
      userData = null
      tokenData = null
      joinData = null 
      findData = null 
    },

    // 토큰 업데이트
    updateToken (tokenData: any = {}) {
      const tokenCookie = useCookie('token')
      this.token = {
        ...this.token,
        ...tokenData
      }
      tokenCookie.value = JSON.stringify(this.token)
    },

    // 로그인
    async authenticateUser (response: any, type: string = 'local') {
      // 토큰 정의
      const userCookie = useCookie('user')
      const tokenCookie = useCookie('token')

      // 사용자 빌드
      const user = {
        login: true,
        type: type,
        id: response.user.id,
        email: response.user.email,
        nickname: response.user.nickname,
        thumbnail: response.user.thumbnail,
        stealth: response.user.stealth
      }
      userCookie.value = JSON.stringify(user)
      this.user = user

      // 토큰 빌드
      const token = {
        access: {
          token: globals.encrypt(response.token.access.token),
          expire: response.token.access.expire
        },
        refresh: {
          token: globals.encrypt(response.token.refresh.token),
          expire: response.token.refresh.expire
        }
      }
      tokenCookie.value = JSON.stringify(token)
      this.token = token

      await this.clearJoin()
      await this.clearFind()
    },
    
    // 로그아웃
    async logOut () {

      // 사용자 정보 제거
      await this.clearToken()
      await this.clearUser()

      // 페이지 이동
      const router = useRouter()
      router.push({
        path: '/',
        force: true
      })

      // 연결된 소셜 계정 로그아웃
      // if (process.client) {
      //   await globalFunctions.logoutGoogle()
      // }
      
    },

    // 사용자 프로필 변경 ( 개인정보 수정 )
    async updateProfile (nickname: string = '', thumbnail: string = '', stealth: string = 'N') {

      // 토큰 정의
      const userCookie = useCookie('user')

      // 사용자 빌드
      const user = {
        ...this.user,
        nickname: nickname,
        thumbnail: thumbnail,
        stealth: stealth
      }
      userCookie.value = JSON.stringify(user)
      this.user = user
    },

    // 통합 제거
    async clearAuthenticate () {
      await this.clearToken()
      await this.clearUser()
    },

    // 사용자 정보 제거
    async clearUser () {
      const userCookie = useCookie('user')
      const user = {
        login: false,
        type: null,
        id: null,
        email: null,
        nickname: null,
        thumbnail: null,
        stealth: null
      }
      userCookie.value = null
      this.user = user
    },

    // 토큰 제거
    async clearToken () {
      const tokenCookie = useCookie('token')
      const token = {
        access: {
          token: '',
          expire: 0
        },
        refresh: {
          token: '',
          expire: 0
        }
      }
      tokenCookie.value = null
      this.token = token
    },

    // 회원가입 제거
    async clearJoin () {
      const joinCookie = useCookie('join')
      const join = {
        email: '',
        code: {
          value: '',
          expire: 0,
          count: 0
        }
      }
      joinCookie.value = null
      this.join = join
    },

    // 아이디 찾기 제거
    async clearFind () {
      const findCookie = useCookie('find')
      const find = {
        email: '',
        code: {
          value: '',
          expire: 0,
          count: 0
        }
      }
      findCookie.value = null
      this.find = find
    },

    // 계정찾기 정보 저장
    async authenticateFind (response: any) {
      // 토큰 정의
      const findCookie = useCookie('find')

      // 사용자 빌드
      const find = {
        email: response.email,
        code: {
          value: globals.encrypt(response.code.value),
          expire: response.code.expire,
          count: response.code.count
        }
      }
      findCookie.value = JSON.stringify(find)
      this.find = find
    },

    // 회원가입 정보 저장
    async authenticateJoin (response: any) {
      // 토큰 정의
      const joinCookie = useCookie('join')

      // 사용자 빌드
      const join = {
        email: response.email,
        code: {
          value: globals.encrypt(response.code.value),
          expire: response.code.expire,
          count: response.code.count
        }
      }
      joinCookie.value = JSON.stringify(join)
      this.join = join
    },

    // 이전 페이지 기록
    // 로그인, 회원가입 이후 라우팅 목적
    updateHistory (to: any, from: any) {
      const history = from.fullPath || '/'
      this.history = history
    },

    // 경험 싱크
    syncExperience () {
      const storage: any = localStorage.getItem(`sp-experience`)
      this.experience = JSON.parse(storage) || {
        game: [],
        board: []
      }
    },

    // 경험 업데이트
    updateExperience (experience: any) {
      localStorage.setItem(`sp-experience`, JSON.stringify(experience.value))
      this.experience = experience.value
    }
  }
})
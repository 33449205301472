import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

// 광고관리
export const globalAdvertise = {

  // 광고
  getAdvertise (section: string = '', type: boolean = false) {

    const { resolution } = storeToRefs(useGlobalStore())
    const $i18n: any = ref(globalVariables.app.i18n)

    let style = ''
    let height = ''

    // 게임 목록 대배너
    if (section === 'game-square') {

      style = resolution.value.width > resolution.value.mobile ? `min-height:400px;` : `200px`
      height = resolution.value.width > resolution.value.mobile ? `100%` : `200px`
      if (type) {
        return this.linkprice_banner_a($i18n.value, height, style)
      } else {
        return `height:${height};`
      }

    // 게임 좌측 큰 배너
    } else if (section === 'game-tee') {

      height = resolution.value.width > resolution.value.mobile ? `150px` : `100px`
      if (type) {
        return this.linkprice_banner_a($i18n.value, height)
      } else {
        return `height:${height};`
      }

    // 게임 우측 작은 배너
    } else if (section === 'game-inside') {

      height = resolution.value.width > resolution.value.mobile ? `100px` : `80px`
      if (type) {
        return this.linkprice_banner_b($i18n.value, height)
      } else {
        return `height:${height};`
      }

    // 게임 통계 페이지
    } else if (section === 'statistics-box') {

      height = resolution.value.width > resolution.value.mobile ? `150px` : `120px`
      if (type) {
        return this.linkprice_banner_a($i18n.value, height)
      } else {
        return `height:${height};`
      }

    // 마이 페이지
    } else if (section === 'me-box') {

      height = resolution.value.width > resolution.value.mobile ? `150px` : `120px`
      if (type) {
        return this.linkprice_banner_a($i18n.value, height)
      } else {
        return `height:${height};`
      }

    // 기본 와이드형 박스
    } else if (section === 'wide-box') {

      height = resolution.value.width > resolution.value.mobile ? `200px` : `140px`
      if (type) {
        return this.linkprice_banner_a($i18n.value, height)
      } else {
        return `height:${height};`
      }

    }

  },

  /*
    링크프라이스 타입 A
    박스형 광고 타입 ( 쿠팡 )
  */
  linkprice_banner_a ($i18n: any = {}, height: string = '', style: string = '') {
    return `
      <iframe
        title="${$i18n.$t('advertise-iframe')}"
        scrolling="no"
        src="https://click.linkprice.com/lpfront/coupang_iframe.php?a_id=A100521042&id=592990"
        height="${height}"
        width="100%"
        border="0"
        marginwidth="0"
        marginheight="0"
        leftmargin="0"
        topmargin="0"
        frameborder="0"
        style="${style}"
      />
    `
  },

  /*
    링크프라이스 타입 b
    상품 진열
  */
  linkprice_banner_b ($i18n: any = {}, height: string = '', style: string = '') {
    return `
      <iframe
        title="${$i18n.$t('advertise-iframe')}"
        scrolling="no"
        src="https://api.linkprice.com/ci/coupang/search/A100521042/${encodeURIComponent(`패션`)}/banner/"
        width="100%"
        height="${height}"
        border="0"
        marginwidth="0"
        marginheight="0"
        leftmargin="0"
        topmargin="0"
        frameborder="0"
        style="${style}"
      />
    `
  }

}
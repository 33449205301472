<template>
  <LoaderInit :isServer="isServer" :isClient="isClient" />
  <LoaderRouter v-if="loaderRouter && popstate.available" :loader="loaderRouter" />
  <component
    :is="LoaderPageUI"
    v-if="!popstate.available"
    :loader="loader"
    :isClient="isClient"
  />
  <NuxtLayout v-show="isClient">
    <v-icon v-resize="onResize" style="display:none;" />
    <component :is="Alert" />
    <component :is="Confirm" />
    <component :is="Preview" />
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'
// import Alert from '~/component/state/alert.vue'
// import Confirm from '~/component/state/confirm.vue'
// import Preview from '~/component/global/preview.vue'
import LoaderInit from '~/component/state/loader-init.vue'
import LoaderRouter from '~/component/state/loader-router.vue'
// import LoaderPage from '~/component/state/loader-page.vue'

export default {
  async setup() {

    // 클라이언트 체크
    // 로더는 빌드일때는 생성하지 않는다.
    const isServer = ref(true)
    const isClient = ref(false)
    const timeout: any = ref(null)

    // 글로벌 도구 업데이트
    globalFunctions.resetAlert()

    // 글로벌 스토어 호출
    const route = useRoute()
    const globalStore = useGlobalStore()
    const { loaderRouter, loaderPage, loader, popstate, layout, resolution } = storeToRefs(useGlobalStore())

    // 레이아웃 정의
    layout.value.name = route.name
    layout.value.path = route.path
    layout.value.query = route.query
    layout.value.params = route.params
    resolution.value.scroll = 0

    // 현재 시간을 정의
    const timestamp = ref(new Date().getTime())

    // 현재 스크롤을 정의
    const scrollPosition = ref(0)

    // 
    onBeforeMount (() => {
      if (timeout.value) {
        clearTimeout(timeout.value)
      }
    })

    // 서버, 클라이언트 체크
    onMounted ( async () => {
      globalStore.setResolution(window.innerWidth, window.innerHeight)
      globalStore.setClient()
      isClient.value = true
      timeout.value = setTimeout(() => {
        isServer.value = false
      }, 300)

      // 리스너 등록
      window.addEventListener('popstate', handlePopstate)
      window.addEventListener('scroll', handleScroll, { passive: true })
    })

    // 리사이즈 체크
    function onResize () {
      globalStore.setResolution(window.innerWidth, window.innerHeight)
    }

    // 스크롤 체크
    function handleScroll() {
      scrollPosition.value = window.scrollY
    }

    // 팝스테이트 핸들러 등록
    function handlePopstate ($event: any) {
      globalStore.setPopstate($event.state)
    }

    // 리스너 제거
    onBeforeUnmount (() => {
      window.removeEventListener('popstate', handlePopstate)
      window.removeEventListener('scroll', handleScroll)
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩
    const Alert: any = shallowRef(null)
    const Confirm: any = shallowRef(null)
    const Preview: any = shallowRef(null)
    const LoaderPageUI: any = shallowRef(null)
    const loadComponent = async () => {
      const Alert_load = await import('~/component/state/alert.vue')
      const Confirm_load = await import('~/component/state/confirm.vue')
      const Preview_load = await import('~/component/global/preview.vue')
      const LoaderPage_load = await import('~/component/state/loader-page.vue')
      Alert.value = Alert_load.default || Alert_load
      Confirm.value = Confirm_load.default || Confirm_load
      Preview.value = Preview_load.default || Preview_load
      LoaderPageUI.value = LoaderPage_load.default || LoaderPage_load
    }
    await loadComponent()

    return {
      isServer,
      isClient,
      loaderRouter,
      loaderPage,
      loader,
      layout,
      popstate,
      timestamp,
      resolution,
      globalStore,
      scrollPosition,
      onResize,
      handleScroll,
      handlePopstate,

      // 컴포넌트
      Alert,
      Confirm,
      Preview,
      LoaderPageUI
    }
  },
  components: {
    // Alert,
    // Confirm,
    // Preview,
    LoaderInit,
    LoaderRouter
    // LoaderPage,
  },
  watch: {
    '$route' (to, from) {

      // // 팝스테이트 상태 체크
      this.timestamp = new Date().getTime()
      if (this.timestamp - this.popstate.timestamp > globalVariables.popstate.gap || !this.popstate.timestamp) {
        this.globalStore.setPopstateUnavailable(true)
      }

      // 다른 페이지로 이동, 오류 초기화
      if (to.path != from.path) {
        globalFunctions.pageError(false)
        this.globalStore.setResolutionScroll(0)
      }
      
      // 페이지 이동시 오류, 로더를 종료
      this.globalStore.setLoader(false)

      // 페이지 기록
      this.layout.name = to.name
      this.layout.path = to.path
      this.layout.query = to.query
      this.layout.params = to.params

    },
    'scrollPosition' (to, from) {
      this.globalStore.setResolutionScroll(to)
    }
  }
}
</script>

<style lang="scss">
  @import '~/assets/style/load';
</style>
export default defineNuxtPlugin( async (nuxtApp) => {

  /*
    전역으로 기억해주자 ( 메모리보다 안정성이 우선인데.. )
    const config = useRuntimeConfig() 이놈이 아주 말썽이다.
    플러그인은 순서대로 진행되니, 가장 먼저 진행되는 이놈을 대상으로 하자.
    nuxt.config.ts 플러그인 순서 정의
    plugins: [
      '~/plugins/token.ts',
      '~/plugins/index.ts',
      '~/plugins/router.ts',
      '~/plugins/i18n.ts',
      '~/plugins/firebase.ts',
      '~/plugins/vuetify.ts'
    ],
  */
  globalVariables.app = {
    i18n: nuxtApp.vueApp.config.globalProperties,
    config: nuxtApp.$config
  }

  try {
    await globalTokens.isToken()
  } catch (error) {
    //
  }
})
// 오류 테스트
// 10 = 10개중 1번 오류
const errorFrequency = 100000000

/*
** 통합 API
** @ 공통 적용
*/
export async function APICALL(
  address: string = '',
  payload: any = {},
  params: any = {},
  proxy: Boolean = true,
  useLoader: boolean = true,
  useError: boolean = true,
  mode: string = ''
){

  // 개발서버라면 무조건 mode 변경
  // if (globals.isDev()) {
  //   if (payload.method === 'POST') {
  //     mode = 'spstrap'
  //   } else {
  //     params.mode = 'spstrap'
  //   }
  // }

  let errorData: any = {}
  const modeUrl = mode === 'spstrap' ? 'mode=spstrap' : '' // 디버깅
  const url = proxy ? `${globalVariables.app.config.public.WEB_URL}${address}` : `${address.includes('http') ? `` : globalVariables.app.config.public.API_URL}${address}`
  const baseUrl = (Math.floor(Math.random() * errorFrequency) + 1) === 1 ? `${globalVariables.app.config.public.API_URL}/force-error-test` : url
  const apiUrl = payload.method === 'POST' ? 
                 `${baseUrl}${modeUrl ? `?${modeUrl}` : ''}` : 
                 `${baseUrl}${globalFunctions.getParams(params)}${modeUrl ? `&${modeUrl}` : ''}`
  const apiParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    },
    ...payload
  }
  
  if (payload.method === 'POST') {
    if (!apiParams.body) {
      if (params instanceof FormData) {
        apiParams.body = params
      } else {
        apiParams.body = globalFunctions.getParams(params).replace('?', '')
      }
    }
  }

  try {

    // 글로벌 로더 시작
    if (useLoader) globalFunctions.pageLoader(true)

    // 글로벌 지연을 위해 setTimeout을 사용합니다. (.3초)
    // await new Promise((resolve) => setTimeout(resolve, globalVariables.api.searchInterval))

    // 실제 API 연결
    if (globals.isDev()) {
      console.log(`%cAPICALL ${apiUrl} ${JSON.stringify(apiParams)}`, 'color: #BA68C8; background: black; padding: 5px 10px; font-weight: bold;')
    }
    const response: any = await new Promise((resolve, reject) => {
      fetch(apiUrl, apiParams)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })

    // 실제 API 연결
    if (globals.isDev()) {
      // console.log(response, '== response ==')
    }

    // 데이터 체크 ( JSON 여부 )
    const isJson = response.headers ? response.headers.get('content-type')?.includes('application/json') : null
    const data = isJson ? await response.json() : response

    // 실제 API 연결
    if (globals.isDev()) {
      // console.log(data, `- 리턴 데이터 ------------------------`)
    }

    // 에러 캐치
    if (response.status != 200) {
      errorData = data || {}
      throw new Error(data)
    }

    // 정상 처리
    if (useLoader) globalFunctions.pageLoader(false)
    if (useError) globalFunctions.pageError(false)
    globalFunctions.isRouter(false)
    globalStores.updateTokenStore()

    // 정상 데이터 리턴
    return {
      success: true,
      status: response.status,
      result: data
    }

  } catch (error: any) {

    // 오류 처리
    if (useLoader) globalFunctions.pageLoader(false)
    if (useError) globalFunctions.pageError(true, errorData?.error?.code)
    globalFunctions.isRouter(false)
    globalStores.updateTokenStore()

    return {
      success: false,
      status: parseInt(error.message),
      result: errorData
    }

  }
}
import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAbnormal, LazyCountry, LazyDate, LazyEllipsis, LazyEmpty, LazyFluctuations, LazyImage, LazyLanguage, LazyLogo, LazyLongtext, LazyNewicon, LazyNumber, LazyPreview, LazyUserList, LazyUser } from '#components'
const lazyGlobalComponents = [
  ["Abnormal", LazyAbnormal],
["Country", LazyCountry],
["Date", LazyDate],
["Ellipsis", LazyEllipsis],
["Empty", LazyEmpty],
["Fluctuations", LazyFluctuations],
["Image", LazyImage],
["Language", LazyLanguage],
["Logo", LazyLogo],
["Longtext", LazyLongtext],
["Newicon", LazyNewicon],
["Number", LazyNumber],
["Preview", LazyPreview],
["UserList", LazyUserList],
["User", LazyUser]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

<template>
  <div v-if="loader" class="loader-init" scope="router">
    <v-progress-linear
      indeterminate
      color="primary"
      absolute
    ></v-progress-linear>
    <div flex="1" class="box" gap="sm" halign="center" valign="center">
      <div class="box" gap="lg" direction="column" halign="center" valign="center">
        <img
          :src="globals.getImage(`/base/logo-symbol-primary.webp`)"
          :alt="`${$t('site-name')}`"
          :width="48"
          :height="48"
          loader="lazy"
        />
        <!-- <Logo
          :type="`symbol`"
          :width="`48`"
          :height="`48`"
        /> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    loader: {
      type: Boolean,
      default () {
        return false
      }
    }
  }
}
</script>

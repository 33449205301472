export default {
  container: () => import("D:/VsCode/_music/web-staging-2/layouts/container.vue").then(m => m.default || m),
  default: () => import("D:/VsCode/_music/web-staging-2/layouts/default.vue").then(m => m.default || m),
  empty: () => import("D:/VsCode/_music/web-staging-2/layouts/empty.vue").then(m => m.default || m),
  expansion: () => import("D:/VsCode/_music/web-staging-2/layouts/expansion.vue").then(m => m.default || m),
  "game-melody-statistics": () => import("D:/VsCode/_music/web-staging-2/layouts/game-melody-statistics.vue").then(m => m.default || m),
  "game-melody": () => import("D:/VsCode/_music/web-staging-2/layouts/game-melody.vue").then(m => m.default || m),
  me: () => import("D:/VsCode/_music/web-staging-2/layouts/me.vue").then(m => m.default || m),
  our: () => import("D:/VsCode/_music/web-staging-2/layouts/our.vue").then(m => m.default || m),
  popup: () => import("D:/VsCode/_music/web-staging-2/layouts/popup.vue").then(m => m.default || m)
}
import { createI18n } from 'vue-i18n'
import ko from './locales/ko.json'
import en from './locales/en.json'
import jp from './locales/jp.json'
import cn from './locales/cn.json'

// 모듈 선언
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $changei18n: (locale: any) => void
  }
}

// 언어 설정
// https://vue-i18n.intlify.dev/guide/integrations/nuxt3.html
// 아이콘 : https://www.flaticon.com/packs/countrys-flags
// {{ $t('hello', { name: 'vue-i18n' }) }}
export default defineNuxtPlugin(nuxtApp => {
  const i18nCookies: any = useCookie('i18n')
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: i18nCookies.value || globalVariables.userAgent?.split('-')[0] || 'kr', // 쿠키 > 설정 > kr
    messages: {
      ko,
      en,
      jp,
      cn
    }
  }) as any
  nuxtApp.vueApp.use(i18n)

  // 언어 변경시 쿠키 변경
  nuxtApp.vueApp.config.globalProperties.$changei18n = (locale: any) => {
    i18nCookies.value = locale
    i18n.global.locale.value = locale
  }
})
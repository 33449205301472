import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'

/*
** 파이어베이스
*/
import { signOut } from 'firebase/auth'

// 전역 함수
export const globalFunctions = {

  // 콘솔 찍기
  console (json: any, description: string = 'console') {
  },

  // 클라이언트 전역 체크
  setClient () {
    const globalStore = useGlobalStore()
    globalStore.setClient()
  },

  // 쿠키 옵션 설정
  cookieOptions () {
    return {
      // domain: config.public.WEB_URL
    }
  },

  // 로그인 상태 업데이트
  isUser () {
    const authStore = useAuthStore()
    authStore.syncCookie()
  },

  // 넉스트 로더
  isRouter (value: boolean) {
    const globalStore = useGlobalStore()
    globalStore.setRouterLoader(value)
  },

  // 페이지 에러
  pageError (value: boolean, msg: string = '') {
    const globalStore = useGlobalStore()
    globalStore.setError(value, msg)
  },

  // 페이지 로더
  pageLoader (value: boolean) {
    const globalStore = useGlobalStore()
    globalStore.setLoader(value)
  },

  // 페이지 레이아웃
  pageLayout (type: string, value: boolean) {
    const globalStore = useGlobalStore()
    globalStore.setLayout(type, value)
  },

  // 광고 상태 업데이트
  setAdvertiseSync (value: boolean) {
    const globalStore = useGlobalStore()
    globalStore.setAdvertiseSync(value)
  },

  // 팝스테이트 쿼리 분석
  getPopstateQuery (queryString: string = '') {
    const regex = /\?(.*)/
    const match = regex.exec(queryString)
    const extractedString: any = match && match[1]
    const params = new URLSearchParams(extractedString)
    const payload: any = { page: 1 }
    for (const [key, value] of params.entries()) {
      const processedKey = key.replace('?', '')
      if (processedKey && value) {
        payload[processedKey] = !isNaN(value as any) ? Number(value) : value
      }
    }
    return payload
  },

  // 스토리지 랜더링이 가능한 상태인지 체크
  isStorageRendering (key: string = '') {
    const { popstate } = storeToRefs(useGlobalStore())
    return Boolean(popstate.value.available && localStorage.getItem(key))
  },

  // 리사이트 체크
  onResize () {
    if (window) {
      const globalStore = useGlobalStore()
      console.log('== RESIZE ==')
      globalStore.setResolution(window.innerWidth, window.innerHeight)
    }
  },

  // 로그아웃
  async logOut () {
    const authStore = useAuthStore()
    await authStore.logOut()
  },

  // 토큰 꼬였을 때
  removeToken () {
    const authStore = useAuthStore()
    authStore.clearUser()
    authStore.clearToken()
  },

  // 기본 알림창
  alert (title: string = '', contents: string = '', button: string = '') {
    const globalStore = useGlobalStore()
    globalStore.toggleAlert(title, contents, button)
  },
  resetAlert () {
    const globalStore = useGlobalStore()
    globalStore.resetAlert()
  },

  // 기본 컨펌창
  confirm (title: string = '', contents: string = '', button: string = '', callback: any = {}) {
    const globalStore = useGlobalStore()
    globalStore.toggleConfirm(title, contents, button, callback)
  },

  // 미리보기
  preview (url: string = '') {
    const globalStore = useGlobalStore()
    globalStore.togglePreview(url)
  },

  // 오브젝트 내부 텍스트 번안
  getTranslateText (menus: any = [], $i18n: any = {}) {
    const translate = menus.map((item: any) => {
      if (item.text) {
        return { ...item, title: $i18n.$t(item.text) }
      }
      return item
    })
    return translate
  },

  // 랭킹 가져오기
  getRankBadges (rank: number = 0) {
    const currentRank = globalVariables.rankBadges[rank-1] || rank
    return currentRank
  },

  // 스크롤 이동하기
  scrollToTop (top: number = 0, left: number = 0) {
    // 연결만 해둔 상태.
    // 필요하다면 target, id 등 만들어서 사용하자
    if (process.client) {
      setTimeout(() => {
        window.scrollTo({
          top: top,
          left: left,
          //behavior: 'smooth'
        })
      }, 30)
    }
  },

  // 시간 경과 체크하기
  getTimeDifference (start: any, end: any, type: string = 'seconds') {
    const startTime: any = new Date(start);
    const endTime: any = new Date(end);
    const timeDifferenceInMilliseconds = endTime - startTime

    switch (type) {
      case 'seconds':
        return Math.floor(timeDifferenceInMilliseconds / 1000);
      case 'minutes':
        return Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
      case 'hours':
        return Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
      case 'days':
        return Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
      case 'years':
        return Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24 * 365));
      default:
        throw new Error('Invalid type specified. Supported types are "seconds", "minutes", "hours", "days".')
    }
  },

  // 시간 경과 유닉스 타입으로 체크하기
  getTimeDifferenceUnix (start: any, end: any) {
    if (isNaN(start) && isNaN(end)) {
      return 0
    } else {
      return Number(end) - Number(start)
    }
  },

  // 초를 초분시일년으로 환산하기
  getNumberToTime (value: number = 0, type: string = 'seconds') {
    switch (type) {
      case 'seconds':
        return Math.floor(value / 1000);
      case 'minutes':
        return Math.floor(value / (1000 * 60));
      case 'hours':
        return Math.floor(value / (1000 * 60 * 60));
      case 'days':
        return Math.floor(value / (1000 * 60 * 60 * 24));
      case 'years':
        return Math.floor(value / (1000 * 60 * 60 * 24 * 365));
      default:
        return value
    }
  },

  // 점수별 컬러 ( 뷰티파이 )
  getScoreColor (value: number = 0) {
    if (value === 100) {
      return 'blue-lighten-1'
    } else if (value > 80) {
      return 'blue-lighten-1'
    } else if (value > 60) {
      return 'teal-lighten-1'
    } else if (value > 40) {
      return 'grey-lighten-1'
    } else if (value > 20) {
      return 'red-darken-1'
    } else if (value > 0) {
      return 'red-darken-3'
    } else { // 플레이를 한번이라도 했다면 0 일수가 없다.
      return ''
    }
  },

  // 타임 리밋 컬러 ( 뷰티파이 )
  getTimelimitColor (value: number = 0) {
    if (value > 90) {
      return 'cyan-darken-1'
    } else if (value > 80) {
      return 'green-darken-2'
    } else if (value > 70) {
      return 'light-green-darken-2'
    } else if (value > 60) {
      return 'amber-darken-1'
    } else if (value > 50) {
      return 'orange-darken-1'
    } else if (value > 40) {
      return 'orange-darken-4'
    } else if (value > 30) {
      return 'red-lighten-1'
    } else if (value > 20) {
      return 'red-darken-1'
    } else if (value > 10) {
      return 'red-darken-4'
    } else {
      return 'red-accent-4'
    }
  },

  // 팝업창 열기
  onPopup (popupWidth: number = 0, popupHeight: number = 0, popupURL: string = '') {

    // 팝업 창의 가운데 위치 계산
    const screenWidth = window.screen.width
    const screenHeight = window.screen.height
    const left = (screenWidth - popupWidth) / 2
    const top = (screenHeight - popupHeight) / 2

    // 팝업 창 옵션
    const popupOptions = `
      width=${popupWidth},
      height=${popupHeight},
      top=${top},
      left=${left},
      resizable=yes,
      scrollbars=yes
    `

    // 팝업 창 열기
    window.open(popupURL, 'popupWindow', popupOptions)
  },

  /*
  ** 동적 번역 메세지 얻기 ( range 포함)
  ** {{ globalFunctions.getRangeTranslate('difficulty', melody.difficulty) }}
  ** {{ globalFunctions.getRangeTranslate('accuracy', melody.difficulty) }}
  ** {{ globalFunctions.getRangeTranslate('stepMessage', 5) }}
  */
  getRangeTranslate (group: string = '', range: any = 0, $i18n: any = {}, fix: number = 0) {
    if (!globalMessages[group]) {
      return $i18n.$t('game-error-translate')
    }
    function returnMessage (msg: any) {
      if (typeof msg === 'string') {
        return $i18n.$t(msg)
      } else if (Array.isArray(msg)) {
        const randomNum = fix ? (fix - 1) : Math.floor(Math.random() * msg.length)
        const randomMsg = msg[randomNum]
        return $i18n.$t(randomMsg)
      }
    }
    for (let i = 0; i <= globalMessages[group].length; i++) {
      if (globalMessages[group][i].range != undefined) {
        if (range <= globalMessages[group][i].range) {
          return returnMessage(globalMessages[group][i].message)
        }
      } else {
        if (i === range - 1) {
          return returnMessage(globalMessages[group][i])
        }
      }
    }
    return returnMessage(globalMessages[group][0].message)
  },

  // 레이팅 수치 고정
  getRatingStar (value: number = 0) {
    return value === 0 ? 0 : 5 - (value / 100 * 5)
  },
  
  // 레이팅 컬러 분기
  getRatingStarColor (value: number = 0) {
    const star = this.getRatingStar(value)
    if (star > 4) {
      return 'red-darken-3'
    } else if (star > 3) {
      return 'orange-darken-4'
    } else if (star > 2) {
      return 'amber-darken-1'
    } else if (star > 1) {
      return 'lime-darken-1'
    } else if (star > 0) {
      return 'green-darken-1'
    } else {
      return 'grey-lighten-1'
    }
  },

  // 랜덤 색상 가져오기 ( 디폴트 )
  getRandomColor () {
    const color = [
      'red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'light-blue', 
      'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange',
      'brown', 'blue-grey', 'grey'
    ]
    return color[Math.floor(Math.random() * color.length)]
  },

  // key, value를 이용해서 변환된 언어 얻기
  getKeyValueTranslate (group: string = '', key: string = '', text: string = '', data: any = []) {
    const $i18n: any = ref(globalVariables.app.i18n)
    data.forEach((item: any) => {
      item[text] = $i18n.value.$t(`${group}-${item[key]}`)
    })
    return data
  },

  // 오브젝트를 GET 주소로 변환
  getParams (params: any = {}) {
    const queryParams = Object.entries(params)
    .filter(([key, value]) => (value !== null && value != '' && value != undefined) || value === 0)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`)
    .join('&')
    return queryParams ? `?${queryParams}` : ''
  },

  // 오브젝트 파라미터를 만든다 ( json )
  getObjectParams (params: any = {}) {
    const result = Object.entries(params).reduce((acc: any, [key, value]) => {
      acc[key] = typeof value === 'object' ? JSON.stringify(value) : value
      return acc
    }, {})
    return result
  },

  // 값이 있는 오브젝트만 반환한다
  getAvailableObjectParams (params: any = {}) {
    const result = Object.entries(params).reduce((acc: any, [key, value]) => {
      if (value || value === 0) {
        acc[key] = value
      }
      return acc
    }, {})
    return result
  },

  // 오브젝트의 코드와 일치하는 키를 반환
  getCodeToText (key: string = '', data: any = []) {
    const foundItem = data.find((arr: any) => arr.code === key)
    const textValue = foundItem ? foundItem.text : ''
    return textValue
  },

  // 오브젝트를 키워드 쉼표로 반환
  getParamsKey (params: any = {}, data: any = []) {
    const queryParams = Object.keys(params)
      .map(key => `${this.getCodeToText(params[key], data)}`)
      .join(', ')
    return queryParams ? `${queryParams}` : ''
  },

  // 홈으로
  goHome () {
    const router = useRouter()
    this.pageError(false)
    router.push({ path: '/', query: { error: Date.now() } });
  },

  // 메타태그 설정
  getMetatag (value: any = {}, route: any = {}, $i18n: any = {}) {
    const { user } = storeToRefs(useAuthStore())
    const { navigator } = storeToRefs(useGlobalStore())
    const lang = value.lang ? value.lang : globalVariables.userAgent?.split('-')[0]
    const title = value.titleText ? value.titleText : value.title ? `${$i18n?.$t(value.title)} | ${$i18n?.$t('meta-title')}` : $i18n?.$t('meta-title')
    const keyword = value.keywordText ? value.keywordText : value.keyword ? value.keyword : $i18n?.$t('meta-keyword')
    const description = value.descriptionText ? value.descriptionText : value.description ? $i18n?.$t(value.description) : $i18n?.$t('meta-description')
    const thumbnail = value.thumbnail ? value.thumbnail : globals.getImage(`/base/logo-meta.webp`)
    const metaTag = {
      htmlAttrs: { lang: lang },
      title,
      meta: [
        // 일반 메타 태그
        { name: 'robots', content: 'index, follow' },
        { name: 'keyword', content: keyword },
        { name: 'description', content: description },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },

        // 오픈 그래프 메타 태그
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:image', content: thumbnail },
        { property: 'og:url', content: route.fullPath },
        { property: 'og:type', content: 'website' },
      ]
    }

    // // 옵저버로 아날리틱스 전송
    // if (window.MutationObserver) {
    //   let count = 0
    //   const target: any = document.querySelector('title')
    //   this.observer = new MutationObserver((mutations) => {
    //     if (this.popstate.timestamp || count) {
    //       mutations.forEach((mutation) => {
    //         globals.getGoogleAnalyticsPush()
    //       })
    //     }
    //     count++
    //   })
    //   this.observer.observe(target, { childList: true })
    // }
    // 메타태그를 변경하려는 시도를 하면 아날리틱스를 소환한다.

    setTimeout(() => {
      globals.getGoogleAnalyticsPush(user.value, navigator.value)
    }, 100)

    return metaTag
  },

  // 메타태그용 데이터 추출
  getParamsMetatag (params: any = {}) {
    const $i18n: any = ref(globalVariables.app.i18n)
    let title = $i18n.value.$t('meta-title')
    if (params.keyword) {
      title = title + ` | ${params.keyword} ${$i18n.value.$t('search-result')}`
    }
    if (params.genre) {
      title = title + ` | ${this.getParamsKey(params.genre, globalVariables.genre)} ${$i18n.value.$t('genre')}`
    }
    if (params.country) {
      title = title + ` | ${this.getParamsKey(params.country, globalVariables.country)} ${$i18n.value.$t('country')}`
    }
    if (params.year) {
      title = title + ` | ${params.year[0]}~${params.year[1]} ${$i18n.value.$t('year')}`
    }
    if (params.page) {
      title = title + ` | ${params.page} ${$i18n.value.$t('page')}`
    }
    return title
  },

  // 연도 센터 선택
  getYearCenter () {
    return [globalVariables.create.year.maxLeeds, globalVariables.create.year.minLeeds]
  },

  // 연도에 따른 배열 생성
  getYearStep () {
    const steps = []
    for (let year = globalVariables.create.year.min; year <= globalVariables.create.year.max; year += globalVariables.create.year.tickStep) {
      // globalVariables.year.push(year)
      steps.push(year)
    }
    return steps
  },

  // 연도에 따른 연령대 생성
  getAgeStep () {
    return globalVariables.age
  },

  // 남은 시간 표기
  expireLastDate (end: number = 0, type: string = 'seconds') {
    const start = new Date().getTime()
    return this.getTimeDifference(start, end, type)
  },

  /*
  ** 파이어베이스
  */
  // 구글 로그아웃
  async logoutGoogle () {
    try {
      await signOut(globalVariables.firebase.auth)
      globalVariables.firebase.user = null
    } catch (error) {
      throw error
    }
  },

  /*
  ** 깊은 복사
  */
  deepCopy (obj: any) {
    if (typeof obj !== 'object' || obj === null) {
      return obj; // 객체가 아니거나 null인 경우 그대로 반환합니다.
    }
    let copy: any;
    if (Array.isArray(obj)) {
      copy = []; // 배열인 경우 빈 배열을 생성합니다.
      for (let i = 0; i < obj.length; i++) {
        copy[i] = this.deepCopy(obj[i]); // 배열 요소들을 재귀적으로 복사합니다.
      }
    } else {
      copy = {}; // 객체인 경우 빈 객체를 생성합니다.
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          copy[key] = this.deepCopy(obj[key]); // 객체의 속성들을 재귀적으로 복사합니다.
        }
      }
    }
    return copy; // 깊은 복사된 객체를 반환합니다.
  },

  // 텍스트 하이라이트
  highlightText(title: string = '', keyword: string = '', color: string = 'red') {
    const regex = new RegExp(keyword, 'g');
    return title.replace(regex, `<span class="text-${color}">${keyword}</span>`);
  },

  // 데이터 섞기
  async shuffleArray(array: any, length: number = 100) {
    const uniqueArray = [...new Set(array)] // 중복 제거
    const shuffledArray: any = []
    while (shuffledArray.length < Math.min(length, uniqueArray.length)) {
      const randomIndex = Math.floor(Math.random() * uniqueArray.length)
      const randomItem = uniqueArray[randomIndex]
      if (!shuffledArray.includes(randomItem)) {
        shuffledArray.push(randomItem)
      }
    }
    return shuffledArray
  },

  /*
  ** 주소타입 검증
  */
  normalizeUrl(url: string = '') {
    const WEB_URL = globalVariables.app.config.public.WEB_URL
    try {
      let normalizedUrl = new URL(url, WEB_URL).toString()
      if (normalizedUrl.endsWith('/')) {
        return normalizedUrl.slice(0, -1)
      }
      return normalizedUrl
    } catch (error) {
      return url
    }
  },

  // 경험 업데이트 하기
  // 그룹은 game, group 두개만 존재한다.
  updateEexperience (group: string = '', key: string = '') {

    if (!process.client) {
      return false
    }

    // 경험 가져오기
    let hit = false
    const now = new Date().getTime()
    const authStore = useAuthStore()
    const experience: any = globalStores.getExperience()
    const target = experience.value[group]
    const filtered = target.find((item: any) => {
      return item.key === key
    })
    if (!filtered) {
      target.push({
        key,
        date: new Date().getTime()
      })
      hit = true
    } else {
      if (filtered.date > now - globalVariables.experience.hit) {
        // 시간 남음
      } else {
        // 시간 지남
        filtered.date = new Date().getTime()
        hit = true
      }
    }

    // 경험 업데이트
    authStore.updateExperience(experience)

    // 리턴
    return hit
  },

  // 링크
  link (url: string = '') {
    const router = useRouter()
    if (url) {
      router.push(url)
    }
  },

  // 현재 페이지의 파라미터 쉽게 받기
  getWindowParams (query: string = '') {
    const match = window?.location?.href?.match(new RegExp(`[\\?&]${query}=([^&]*)`))
    return match ? decodeURIComponent(match[1]) : ''
  },

  // 레이아웃 업데이트..
  // 넉스트 오류라서 이건 방법이 없다.
  updateLayout (name: string = '') {
    if (name === 'game-melody-statistics') {
      const layout = document.querySelector('.layout')
      const contents = document.querySelector('.contents')
      const footer = document.querySelector('.footer')
      if (layout && contents && footer) {
        layout.classList.remove('default')
        layout.classList.remove('expansion')
        layout.classList.add('container')
        contents.classList.add('expansion')
        const footerInside = footer.querySelector('.footer-inside > .box')
        if (footerInside) {
          footerInside.setAttribute('pd', 'md')
        }
      }
    }
  },

  // 유저 닉네임 분기
  getUserNickname (user: any = {}, type: string = 'nickname', $t: any = {}) {
    if (type === 'nickname') {
      if (user.stealth === 'Y') {
        return $t('stealth')
      } else if (!user.id) {
        return $t('guest')
      } else {
        return user.nickname
      }
    } else if (type === 'shortcut') {
      if (user.stealth === 'Y') {
        return '🙈'
      } else if (!user.id) {
        return '👽'
      } else {
        return user.nickname?.slice(0, 1)
      }
    } else if (type === 'color') {
      if (user.stealth === 'Y') {
        return 'orange'
      } else if (!user.id) {
        return 'green'
      } else {
        return globalVariables.userset.color
      }
    }
  }
}
import CryptoJS from 'crypto-js'
import { logEvent } from 'firebase/analytics'

/*
** 기본 함수
** 절대 다른거 import 하면 안된다
*/
export const globals = {

  // 개발서버 체크
  isDev () {
    if (process.env.NODE_ENV === 'development' || globalVariables.app?.$config?.public?.MODE === 'staging' || process.env.STATE === 'GENERATE') {
      return true
    } else {
      return false
    }
  },

  // 개발서버 체크
  isBuild () {
    // 빌드 상태 체크
    const isGenerate = process.env.NITRO === 'generate';

    // 혹은 ctx 또는 context를 사용해서도 빌드 상태를 체크할 수 있습니다.
    return isGenerate
  },

  // 암호화 ( nuxt.config )
  // https://github.com/tpenaranda/vue-cryptojs
  nuxtEncrypt (key: string = '', value: string = '') {
    const tokenKey: any = process.env.TOKEN_KEY || ''
    return CryptoJS.AES.encrypt(value, tokenKey).toString()
  },

  // 암호화 ( 일반 )
  encrypt (value: string = '') {
    try {
      //const cryptojs = inject('cryptojs') as typeof CryptoJS
      const cryptoValue = CryptoJS.AES.encrypt(value, globalVariables.app.config.public.TOKEN_KEY).toString()
      return cryptoValue
    } catch (error) {
      return ''
    }
  },

  // 복호화 ( nuxt.config )
  nuxtDecrypt (value: string = '') {
    const tokenKey: any = process.env.TOKEN_KEY || ''
    return CryptoJS.AES.decrypt(value, tokenKey).toString()
  },

  // 복호화 ( 일반 )
  decrypt (value: string = '') {
    try {
      //const cryptojs = inject('cryptojs') as typeof CryptoJS
      const cryptoValue = CryptoJS.AES.decrypt(value, globalVariables.app.config.public.TOKEN_KEY).toString(CryptoJS.enc.Utf8)
      return cryptoValue
    } catch (error) {
      return ''
    }
  },

  // 만료시간 컨트롤
  // 프론트는 시간이 남았지만 백엔드엔 만료인 경우를 체크할 목적
  expire (value: number = 0) {
    return value - globalVariables.token.gap
  },

  // 시간 만료 여부 체크
  isExpire (date: number = 0) {
    const now = new Date().getTime()
    return Boolean(now - date > 0)
  },

  // 숫자형으로 변환
  toNumber (value: any) {
    const numericValue = parseFloat(value);
    return !isNaN(numericValue) ? numericValue : value
  },

  // 콤마형으로 변환
  toComma (value: number) {
    const num = isNaN(value) ? 0 : Number(value)
    return num.toLocaleString()
  },

  // 새로고침 ( 강제 )
  refresh () {
    window?.location?.reload()
  },

  // 뒤로가기
  // 더 좋은 방법이 있다면 변경하자.
  goBack () {
    const router: any = useRouter()
    if (router._routePreloaded) {
      router.go(-1)
    } else {
      router.push({
        path: '/',
        force: true
      })
    }
  },

  // 랜덤한 문자열 생성 함수
  getRandomString (length: number) {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      result += charset.charAt(randomIndex)
    }
    return result
  },

  // 서버 이미지 주소 연결
  getNuxtImage (path: string = '') {
    return `${process.env.IMG_URL}${path}`
  },

  // 이미지 주소 연결
  getImage (path: string = '') {
    if (process.env.IMG_URL) {
      return `${process.env.IMG_URL}${path}`
    } else if (globalVariables.app.config.public?.IMG_URL) {
      return `${globalVariables.app.config.public?.IMG_URL}${path}`
    } else {
      return ''
    }
  },

  getGoogleAnalyticsPush (user: any = { id: 0 }, navigator: any = { country: '' }, text: any = '') {
    if (process.client && document) {

      // 아날리틱스 분석 -----------------------------------------------------
      let page_name: string|null = `${document.title}${text ? ` - ${text}` : ``}`
      let params: object|null = {
        // name: '이런~',
        // app_name: 'ㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋ',
        // screen_name: document.title,
        page_name: page_name,
        // page_title: document.title, // 이건 대체...
        // page_location: route.fullPath,
        // page_id: route.params.id,
        user_id: user.id,
        user_nickname: user.nickname,
        language: navigator.country,
        // href: route.fullPath,
        timestamp: new Date().toISOString()
      }

      // 전송
      if (this.isDev()) {
        console.log(params, `Google Analytics Push`)
      } else {
        logEvent(globalVariables.firebase.analytics, 'page_view', {
          ...params
        })
      }
      
      // 메모리 제거
      page_name = null
      params = null

    }
  }

}
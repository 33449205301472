<template>
  <div class="loader-init" v-if="isServer" :animation="isClient ? `fade-out` : ``">
    <div flex="1" class="box" gap="sm" halign="center" valign="center">
      <div class="box" gap="lg" direction="column" halign="center" valign="center">
        <img
          :src="globals.getImage(`/base/logo-symbol-primary.webp`)"
          :alt="`${$t('site-name')}`"
          :width="48"
          :height="48"
          loader="lazy"
        />
        <!-- <Logo
          :type="`symbol`"
          :width="`48`"
          :height="`48`"
        /> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    isServer: {
      type: Boolean,
      default () {
        return false
      }
    },
    isClient: {
      type: Boolean,
      default () {
        return false
      }
    }
  }
}
</script>
// 전역 링크
export const globalMessages: any = {
  "accuracy": [
    { 
      "range": 0,
      "message": [
        "game-accuracy-message-0-1",
        "game-accuracy-message-0-2",
        "game-accuracy-message-0-3",
        "game-accuracy-message-0-4",
        "game-accuracy-message-0-5"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-accuracy-message-20-1",
        "game-accuracy-message-20-2",
        "game-accuracy-message-20-3",
        "game-accuracy-message-20-4",
        "game-accuracy-message-20-5"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-accuracy-message-40-1",
        "game-accuracy-message-40-2",
        "game-accuracy-message-40-3",
        "game-accuracy-message-40-4",
        "game-accuracy-message-40-5"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-accuracy-message-60-1",
        "game-accuracy-message-60-2",
        "game-accuracy-message-60-3",
        "game-accuracy-message-60-4",
        "game-accuracy-message-60-5"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-accuracy-message-80-1",
        "game-accuracy-message-80-2",
        "game-accuracy-message-80-3",
        "game-accuracy-message-80-4",
        "game-accuracy-message-80-5"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-accuracy-message-100-1",
        "game-accuracy-message-100-2",
        "game-accuracy-message-100-3",
        "game-accuracy-message-100-4",
        "game-accuracy-message-100-5"
      ]
    }
  ],
  "difficulty": [
    {
      "range": 0,
      "message": [
        "game-difficulty-message-0-1",
        "game-difficulty-message-0-2",
        "game-difficulty-message-0-3",
        "game-difficulty-message-0-4",
        "game-difficulty-message-0-5"
      ]
    },
    {
      "range": 20,
      "message": [
        "game-difficulty-message-20-1",
        "game-difficulty-message-20-2",
        "game-difficulty-message-20-3",
        "game-difficulty-message-20-4",
        "game-difficulty-message-20-5"
      ]
    },
    {
      "range": 40,
      "message": [
        "game-difficulty-message-40-1",
        "game-difficulty-message-40-2",
        "game-difficulty-message-40-3",
        "game-difficulty-message-40-4",
        "game-difficulty-message-40-5"
      ]
    },
    {
      "range": 60,
      "message": [
        "game-difficulty-message-80-1",
        "game-difficulty-message-80-2",
        "game-difficulty-message-80-3",
        "game-difficulty-message-80-4",
        "game-difficulty-message-80-5"
      ]
    },
    {
      "range": 80,
      "message": [
        "game-difficulty-message-60-1",
        "game-difficulty-message-60-2",
        "game-difficulty-message-60-3",
        "game-difficulty-message-60-4",
        "game-difficulty-message-60-5"
      ]
    },
    {
      "range": 100,
      "message": [
        "game-difficulty-message-100-1",
        "game-difficulty-message-100-2",
        "game-difficulty-message-100-3",
        "game-difficulty-message-100-4",
        "game-difficulty-message-100-5"
      ]
    }
  ],
  "stepMessage": [
    [
      "game-step-message-1-0",
      "game-step-message-1-1",
      "game-step-message-1-2",
      "game-step-message-1-3",
      "game-step-message-1-4",
      "game-step-message-1-5",
      "game-step-message-1-6",
      "game-step-message-1-7",
      "game-step-message-1-8",
      "game-step-message-1-9"
    ],
    [
      "game-step-message-2-0",
      "game-step-message-2-1",
      "game-step-message-2-2",
      "game-step-message-2-3",
      "game-step-message-2-4",
      "game-step-message-2-5",
      "game-step-message-2-6",
      "game-step-message-2-7",
      "game-step-message-2-8",
      "game-step-message-2-9"
    ],
    [
      "game-step-message-3-0",
      "game-step-message-3-1",
      "game-step-message-3-2",
      "game-step-message-3-3",
      "game-step-message-3-4",
      "game-step-message-3-5",
      "game-step-message-3-6",
      "game-step-message-3-7",
      "game-step-message-3-8",
      "game-step-message-3-9"
    ],
    [
      "game-step-message-4-0",
      "game-step-message-4-1",
      "game-step-message-4-2",
      "game-step-message-4-3",
      "game-step-message-4-4",
      "game-step-message-4-5",
      "game-step-message-4-6",
      "game-step-message-4-7",
      "game-step-message-4-8",
      "game-step-message-4-9"
    ],
    [
      "game-step-message-5-0",
      "game-step-message-5-1",
      "game-step-message-5-2",
      "game-step-message-5-3",
      "game-step-message-5-4",
      "game-step-message-5-5",
      "game-step-message-5-6",
      "game-step-message-5-7",
      "game-step-message-5-8",
      "game-step-message-5-9"
    ]
  ],
  "statisticsScore": [
    {
      "range": 0,
      "message": [
        "game-statistics-score-0-1"
      ]
    },
    {
      "range": 20,
      "message": [
        "game-statistics-score-20-1"
      ]
    },
    {
      "range": 40,
      "message": [
        "game-statistics-score-40-1"
      ]
    },
    {
      "range": 60,
      "message": [
        "game-statistics-score-60-1"
      ]
    },
    {
      "range": 80,
      "message": [
        "game-statistics-score-80-1"
      ]
    },
    {
      "range": 100,
      "message": [
        "game-statistics-score-100-1"
      ]
    }
  ],
  "evaluation": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-message-0-1",
        "game-evaluation-message-0-2",
        "game-evaluation-message-0-3",
        "game-evaluation-message-0-4",
        "game-evaluation-message-0-5",
        "game-evaluation-message-0-6",
        "game-evaluation-message-0-7",
        "game-evaluation-message-0-8",
        "game-evaluation-message-0-9",
        "game-evaluation-message-0-10",
        "game-evaluation-message-0-11",
        "game-evaluation-message-0-12",
        "game-evaluation-message-0-13",
        "game-evaluation-message-0-14",
        "game-evaluation-message-0-15",
        "game-evaluation-message-0-16",
        "game-evaluation-message-0-17",
        "game-evaluation-message-0-18",
        "game-evaluation-message-0-19",
        "game-evaluation-message-0-20"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-message-20-1",
        "game-evaluation-message-20-2",
        "game-evaluation-message-20-3",
        "game-evaluation-message-20-4",
        "game-evaluation-message-20-5",
        "game-evaluation-message-20-6",
        "game-evaluation-message-20-7",
        "game-evaluation-message-20-8",
        "game-evaluation-message-20-9",
        "game-evaluation-message-20-10",
        "game-evaluation-message-20-11",
        "game-evaluation-message-20-12",
        "game-evaluation-message-20-13",
        "game-evaluation-message-20-14",
        "game-evaluation-message-20-15",
        "game-evaluation-message-20-16",
        "game-evaluation-message-20-17",
        "game-evaluation-message-20-18",
        "game-evaluation-message-20-19",
        "game-evaluation-message-20-20"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-message-40-1",
        "game-evaluation-message-40-2",
        "game-evaluation-message-40-3",
        "game-evaluation-message-40-4",
        "game-evaluation-message-40-5",
        "game-evaluation-message-40-6",
        "game-evaluation-message-40-7",
        "game-evaluation-message-40-8",
        "game-evaluation-message-40-9",
        "game-evaluation-message-40-10",
        "game-evaluation-message-40-11",
        "game-evaluation-message-40-12",
        "game-evaluation-message-40-13",
        "game-evaluation-message-40-14",
        "game-evaluation-message-40-15",
        "game-evaluation-message-40-16",
        "game-evaluation-message-40-17",
        "game-evaluation-message-40-18",
        "game-evaluation-message-40-19",
        "game-evaluation-message-40-20"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-message-60-1",
        "game-evaluation-message-60-2",
        "game-evaluation-message-60-3",
        "game-evaluation-message-60-4",
        "game-evaluation-message-60-5",
        "game-evaluation-message-60-6",
        "game-evaluation-message-60-7",
        "game-evaluation-message-60-8",
        "game-evaluation-message-60-9",
        "game-evaluation-message-60-10",
        "game-evaluation-message-60-11",
        "game-evaluation-message-60-12",
        "game-evaluation-message-60-13",
        "game-evaluation-message-60-14",
        "game-evaluation-message-60-15",
        "game-evaluation-message-60-16",
        "game-evaluation-message-60-17",
        "game-evaluation-message-60-18",
        "game-evaluation-message-60-19",
        "game-evaluation-message-60-20"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-message-80-1",
        "game-evaluation-message-80-2",
        "game-evaluation-message-80-3",
        "game-evaluation-message-80-4",
        "game-evaluation-message-80-5",
        "game-evaluation-message-80-6",
        "game-evaluation-message-80-7",
        "game-evaluation-message-80-8",
        "game-evaluation-message-80-9",
        "game-evaluation-message-80-10",
        "game-evaluation-message-80-11",
        "game-evaluation-message-80-12",
        "game-evaluation-message-80-13",
        "game-evaluation-message-80-14",
        "game-evaluation-message-80-15",
        "game-evaluation-message-80-16",
        "game-evaluation-message-80-17",
        "game-evaluation-message-80-18",
        "game-evaluation-message-80-19",
        "game-evaluation-message-80-20"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-message-100-1",
        "game-evaluation-message-100-2",
        "game-evaluation-message-100-3",
        "game-evaluation-message-100-4",
        "game-evaluation-message-100-5",
        "game-evaluation-message-100-6",
        "game-evaluation-message-100-7",
        "game-evaluation-message-100-8",
        "game-evaluation-message-100-9",
        "game-evaluation-message-100-10",
        "game-evaluation-message-100-11",
        "game-evaluation-message-100-12",
        "game-evaluation-message-100-13",
        "game-evaluation-message-100-14",
        "game-evaluation-message-100-15",
        "game-evaluation-message-100-16",
        "game-evaluation-message-100-17",
        "game-evaluation-message-100-18",
        "game-evaluation-message-100-19",
        "game-evaluation-message-100-20"
      ]
    }
  ],
  "evaluation-score": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-score-message-0-1",
        "game-evaluation-score-message-0-2",
        "game-evaluation-score-message-0-3",
        "game-evaluation-score-message-0-4",
        "game-evaluation-score-message-0-5",
        "game-evaluation-score-message-0-6",
        "game-evaluation-score-message-0-7",
        "game-evaluation-score-message-0-8",
        "game-evaluation-score-message-0-9",
        "game-evaluation-score-message-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-score-message-20-1",
        "game-evaluation-score-message-20-2",
        "game-evaluation-score-message-20-3",
        "game-evaluation-score-message-20-4",
        "game-evaluation-score-message-20-5",
        "game-evaluation-score-message-20-6",
        "game-evaluation-score-message-20-7",
        "game-evaluation-score-message-20-8",
        "game-evaluation-score-message-20-9",
        "game-evaluation-score-message-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-score-message-40-1",
        "game-evaluation-score-message-40-2",
        "game-evaluation-score-message-40-3",
        "game-evaluation-score-message-40-4",
        "game-evaluation-score-message-40-5",
        "game-evaluation-score-message-40-6",
        "game-evaluation-score-message-40-7",
        "game-evaluation-score-message-40-8",
        "game-evaluation-score-message-40-9",
        "game-evaluation-score-message-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-score-message-60-1",
        "game-evaluation-score-message-60-2",
        "game-evaluation-score-message-60-3",
        "game-evaluation-score-message-60-4",
        "game-evaluation-score-message-60-5",
        "game-evaluation-score-message-60-6",
        "game-evaluation-score-message-60-7",
        "game-evaluation-score-message-60-8",
        "game-evaluation-score-message-60-9",
        "game-evaluation-score-message-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-score-message-80-1",
        "game-evaluation-score-message-80-2",
        "game-evaluation-score-message-80-3",
        "game-evaluation-score-message-80-4",
        "game-evaluation-score-message-80-5",
        "game-evaluation-score-message-80-6",
        "game-evaluation-score-message-80-7",
        "game-evaluation-score-message-80-8",
        "game-evaluation-score-message-80-9",
        "game-evaluation-score-message-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-score-message-100-1",
        "game-evaluation-score-message-100-2",
        "game-evaluation-score-message-100-3",
        "game-evaluation-score-message-100-4",
        "game-evaluation-score-message-100-5",
        "game-evaluation-score-message-100-6",
        "game-evaluation-score-message-100-7",
        "game-evaluation-score-message-100-8",
        "game-evaluation-score-message-100-9",
        "game-evaluation-score-message-100-10"
      ]
    }
  ],
  "evaluation-correct": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-correct-message-0-1",
        "game-evaluation-correct-message-0-2",
        "game-evaluation-correct-message-0-3",
        "game-evaluation-correct-message-0-4",
        "game-evaluation-correct-message-0-5",
        "game-evaluation-correct-message-0-6",
        "game-evaluation-correct-message-0-7",
        "game-evaluation-correct-message-0-8",
        "game-evaluation-correct-message-0-9",
        "game-evaluation-correct-message-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-correct-message-20-1",
        "game-evaluation-correct-message-20-2",
        "game-evaluation-correct-message-20-3",
        "game-evaluation-correct-message-20-4",
        "game-evaluation-correct-message-20-5",
        "game-evaluation-correct-message-20-6",
        "game-evaluation-correct-message-20-7",
        "game-evaluation-correct-message-20-8",
        "game-evaluation-correct-message-20-9",
        "game-evaluation-correct-message-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-correct-message-40-1",
        "game-evaluation-correct-message-40-2",
        "game-evaluation-correct-message-40-3",
        "game-evaluation-correct-message-40-4",
        "game-evaluation-correct-message-40-5",
        "game-evaluation-correct-message-40-6",
        "game-evaluation-correct-message-40-7",
        "game-evaluation-correct-message-40-8",
        "game-evaluation-correct-message-40-9",
        "game-evaluation-correct-message-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-correct-message-60-1",
        "game-evaluation-correct-message-60-2",
        "game-evaluation-correct-message-60-3",
        "game-evaluation-correct-message-60-4",
        "game-evaluation-correct-message-60-5",
        "game-evaluation-correct-message-60-6",
        "game-evaluation-correct-message-60-7",
        "game-evaluation-correct-message-60-8",
        "game-evaluation-correct-message-60-9",
        "game-evaluation-correct-message-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-correct-message-80-1",
        "game-evaluation-correct-message-80-2",
        "game-evaluation-correct-message-80-3",
        "game-evaluation-correct-message-80-4",
        "game-evaluation-correct-message-80-5",
        "game-evaluation-correct-message-80-6",
        "game-evaluation-correct-message-80-7",
        "game-evaluation-correct-message-80-8",
        "game-evaluation-correct-message-80-9",
        "game-evaluation-correct-message-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-correct-message-100-1",
        "game-evaluation-correct-message-100-2",
        "game-evaluation-correct-message-100-3",
        "game-evaluation-correct-message-100-4",
        "game-evaluation-correct-message-100-5",
        "game-evaluation-correct-message-100-6",
        "game-evaluation-correct-message-100-7",
        "game-evaluation-correct-message-100-8",
        "game-evaluation-correct-message-100-9",
        "game-evaluation-correct-message-100-10"
      ]
    }
  ],
  "evaluation-hint": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-hint-message-0-1",
        // "game-evaluation-hint-message-0-2",
        // "game-evaluation-hint-message-0-3",
        // "game-evaluation-hint-message-0-4",
        // "game-evaluation-hint-message-0-5",
        // "game-evaluation-hint-message-0-6",
        // "game-evaluation-hint-message-0-7",
        // "game-evaluation-hint-message-0-8",
        // "game-evaluation-hint-message-0-9",
        // "game-evaluation-hint-message-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-hint-message-20-1",
        // "game-evaluation-hint-message-20-2",
        // "game-evaluation-hint-message-20-3",
        // "game-evaluation-hint-message-20-4",
        // "game-evaluation-hint-message-20-5",
        // "game-evaluation-hint-message-20-6",
        // "game-evaluation-hint-message-20-7",
        // "game-evaluation-hint-message-20-8",
        // "game-evaluation-hint-message-20-9",
        // "game-evaluation-hint-message-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-hint-message-40-1",
        // "game-evaluation-hint-message-40-2",
        // "game-evaluation-hint-message-40-3",
        // "game-evaluation-hint-message-40-4",
        // "game-evaluation-hint-message-40-5",
        // "game-evaluation-hint-message-40-6",
        // "game-evaluation-hint-message-40-7",
        // "game-evaluation-hint-message-40-8",
        // "game-evaluation-hint-message-40-9",
        // "game-evaluation-hint-message-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-hint-message-60-1",
        // "game-evaluation-hint-message-60-2",
        // "game-evaluation-hint-message-60-3",
        // "game-evaluation-hint-message-60-4",
        // "game-evaluation-hint-message-60-5",
        // "game-evaluation-hint-message-60-6",
        // "game-evaluation-hint-message-60-7",
        // "game-evaluation-hint-message-60-8",
        // "game-evaluation-hint-message-60-9",
        // "game-evaluation-hint-message-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-hint-message-80-1",
        // "game-evaluation-hint-message-80-2",
        // "game-evaluation-hint-message-80-3",
        // "game-evaluation-hint-message-80-4",
        // "game-evaluation-hint-message-80-5",
        // "game-evaluation-hint-message-80-6",
        // "game-evaluation-hint-message-80-7",
        // "game-evaluation-hint-message-80-8",
        // "game-evaluation-hint-message-80-9",
        // "game-evaluation-hint-message-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-hint-message-100-1",
        // "game-evaluation-hint-message-100-2",
        // "game-evaluation-hint-message-100-3",
        // "game-evaluation-hint-message-100-4",
        // "game-evaluation-hint-message-100-5",
        // "game-evaluation-hint-message-100-6",
        // "game-evaluation-hint-message-100-7",
        // "game-evaluation-hint-message-100-8",
        // "game-evaluation-hint-message-100-9",
        // "game-evaluation-hint-message-100-10"
      ]
    }
  ],
  "evaluation-hint-title": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-hint-title-0-1",
        // "game-evaluation-hint-title-0-2",
        // "game-evaluation-hint-title-0-3",
        // "game-evaluation-hint-title-0-4",
        // "game-evaluation-hint-title-0-5",
        // "game-evaluation-hint-title-0-6",
        // "game-evaluation-hint-title-0-7",
        // "game-evaluation-hint-title-0-8",
        // "game-evaluation-hint-title-0-9",
        // "game-evaluation-hint-title-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-hint-title-20-1",
        // "game-evaluation-hint-title-20-2",
        // "game-evaluation-hint-title-20-3",
        // "game-evaluation-hint-title-20-4",
        // "game-evaluation-hint-title-20-5",
        // "game-evaluation-hint-title-20-6",
        // "game-evaluation-hint-title-20-7",
        // "game-evaluation-hint-title-20-8",
        // "game-evaluation-hint-title-20-9",
        // "game-evaluation-hint-title-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-hint-title-40-1",
        // "game-evaluation-hint-title-40-2",
        // "game-evaluation-hint-title-40-3",
        // "game-evaluation-hint-title-40-4",
        // "game-evaluation-hint-title-40-5",
        // "game-evaluation-hint-title-40-6",
        // "game-evaluation-hint-title-40-7",
        // "game-evaluation-hint-title-40-8",
        // "game-evaluation-hint-title-40-9",
        // "game-evaluation-hint-title-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-hint-title-60-1",
        // "game-evaluation-hint-title-60-2",
        // "game-evaluation-hint-title-60-3",
        // "game-evaluation-hint-title-60-4",
        // "game-evaluation-hint-title-60-5",
        // "game-evaluation-hint-title-60-6",
        // "game-evaluation-hint-title-60-7",
        // "game-evaluation-hint-title-60-8",
        // "game-evaluation-hint-title-60-9",
        // "game-evaluation-hint-title-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-hint-title-80-1",
        // "game-evaluation-hint-title-80-2",
        // "game-evaluation-hint-title-80-3",
        // "game-evaluation-hint-title-80-4",
        // "game-evaluation-hint-title-80-5",
        // "game-evaluation-hint-title-80-6",
        // "game-evaluation-hint-title-80-7",
        // "game-evaluation-hint-title-80-8",
        // "game-evaluation-hint-title-80-9",
        // "game-evaluation-hint-title-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-hint-title-100-1",
        // "game-evaluation-hint-title-100-2",
        // "game-evaluation-hint-title-100-3",
        // "game-evaluation-hint-title-100-4",
        // "game-evaluation-hint-title-100-5",
        // "game-evaluation-hint-title-100-6",
        // "game-evaluation-hint-title-100-7",
        // "game-evaluation-hint-title-100-8",
        // "game-evaluation-hint-title-100-9",
        // "game-evaluation-hint-title-100-10"
      ]
    }
  ],
  "evaluation-repeat": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-repeat-message-0-1",
        // "game-evaluation-repeat-message-0-2",
        // "game-evaluation-repeat-message-0-3",
        // "game-evaluation-repeat-message-0-4",
        // "game-evaluation-repeat-message-0-5",
        // "game-evaluation-repeat-message-0-6",
        // "game-evaluation-repeat-message-0-7",
        // "game-evaluation-repeat-message-0-8",
        // "game-evaluation-repeat-message-0-9",
        // "game-evaluation-repeat-message-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-repeat-message-20-1",
        // "game-evaluation-repeat-message-20-2",
        // "game-evaluation-repeat-message-20-3",
        // "game-evaluation-repeat-message-20-4",
        // "game-evaluation-repeat-message-20-5",
        // "game-evaluation-repeat-message-20-6",
        // "game-evaluation-repeat-message-20-7",
        // "game-evaluation-repeat-message-20-8",
        // "game-evaluation-repeat-message-20-9",
        // "game-evaluation-repeat-message-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-repeat-message-40-1",
        // "game-evaluation-repeat-message-40-2",
        // "game-evaluation-repeat-message-40-3",
        // "game-evaluation-repeat-message-40-4",
        // "game-evaluation-repeat-message-40-5",
        // "game-evaluation-repeat-message-40-6",
        // "game-evaluation-repeat-message-40-7",
        // "game-evaluation-repeat-message-40-8",
        // "game-evaluation-repeat-message-40-9",
        // "game-evaluation-repeat-message-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-repeat-message-60-1",
        // "game-evaluation-repeat-message-60-2",
        // "game-evaluation-repeat-message-60-3",
        // "game-evaluation-repeat-message-60-4",
        // "game-evaluation-repeat-message-60-5",
        // "game-evaluation-repeat-message-60-6",
        // "game-evaluation-repeat-message-60-7",
        // "game-evaluation-repeat-message-60-8",
        // "game-evaluation-repeat-message-60-9",
        // "game-evaluation-repeat-message-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-repeat-message-80-1",
        // "game-evaluation-repeat-message-80-2",
        // "game-evaluation-repeat-message-80-3",
        // "game-evaluation-repeat-message-80-4",
        // "game-evaluation-repeat-message-80-5",
        // "game-evaluation-repeat-message-80-6",
        // "game-evaluation-repeat-message-80-7",
        // "game-evaluation-repeat-message-80-8",
        // "game-evaluation-repeat-message-80-9",
        // "game-evaluation-repeat-message-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-repeat-message-100-1",
        // "game-evaluation-repeat-message-100-2",
        // "game-evaluation-repeat-message-100-3",
        // "game-evaluation-repeat-message-100-4",
        // "game-evaluation-repeat-message-100-5",
        // "game-evaluation-repeat-message-100-6",
        // "game-evaluation-repeat-message-100-7",
        // "game-evaluation-repeat-message-100-8",
        // "game-evaluation-repeat-message-100-9",
        // "game-evaluation-repeat-message-100-10"
      ]
    }
  ],
  "evaluation-repeat-title": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-repeat-title-0-1",
        // "game-evaluation-repeat-title-0-2",
        // "game-evaluation-repeat-title-0-3",
        // "game-evaluation-repeat-title-0-4",
        // "game-evaluation-repeat-title-0-5",
        // "game-evaluation-repeat-title-0-6",
        // "game-evaluation-repeat-title-0-7",
        // "game-evaluation-repeat-title-0-8",
        // "game-evaluation-repeat-title-0-9",
        // "game-evaluation-repeat-title-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-repeat-title-20-1",
        // "game-evaluation-repeat-title-20-2",
        // "game-evaluation-repeat-title-20-3",
        // "game-evaluation-repeat-title-20-4",
        // "game-evaluation-repeat-title-20-5",
        // "game-evaluation-repeat-title-20-6",
        // "game-evaluation-repeat-title-20-7",
        // "game-evaluation-repeat-title-20-8",
        // "game-evaluation-repeat-title-20-9",
        // "game-evaluation-repeat-title-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-repeat-title-40-1",
        // "game-evaluation-repeat-title-40-2",
        // "game-evaluation-repeat-title-40-3",
        // "game-evaluation-repeat-title-40-4",
        // "game-evaluation-repeat-title-40-5",
        // "game-evaluation-repeat-title-40-6",
        // "game-evaluation-repeat-title-40-7",
        // "game-evaluation-repeat-title-40-8",
        // "game-evaluation-repeat-title-40-9",
        // "game-evaluation-repeat-title-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-repeat-title-60-1",
        // "game-evaluation-repeat-title-60-2",
        // "game-evaluation-repeat-title-60-3",
        // "game-evaluation-repeat-title-60-4",
        // "game-evaluation-repeat-title-60-5",
        // "game-evaluation-repeat-title-60-6",
        // "game-evaluation-repeat-title-60-7",
        // "game-evaluation-repeat-title-60-8",
        // "game-evaluation-repeat-title-60-9",
        // "game-evaluation-repeat-title-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-repeat-title-80-1",
        // "game-evaluation-repeat-title-80-2",
        // "game-evaluation-repeat-title-80-3",
        // "game-evaluation-repeat-title-80-4",
        // "game-evaluation-repeat-title-80-5",
        // "game-evaluation-repeat-title-80-6",
        // "game-evaluation-repeat-title-80-7",
        // "game-evaluation-repeat-title-80-8",
        // "game-evaluation-repeat-title-80-9",
        // "game-evaluation-repeat-title-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-repeat-title-100-1",
        // "game-evaluation-repeat-title-100-2",
        // "game-evaluation-repeat-title-100-3",
        // "game-evaluation-repeat-title-100-4",
        // "game-evaluation-repeat-title-100-5",
        // "game-evaluation-repeat-title-100-6",
        // "game-evaluation-repeat-title-100-7",
        // "game-evaluation-repeat-title-100-8",
        // "game-evaluation-repeat-title-100-9",
        // "game-evaluation-repeat-title-100-10"
      ]
    }
  ],
  "evaluation-answer": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-answer-message-0-1",
        // "game-evaluation-answer-message-0-2",
        // "game-evaluation-answer-message-0-3",
        // "game-evaluation-answer-message-0-4",
        // "game-evaluation-answer-message-0-5",
        // "game-evaluation-answer-message-0-6",
        // "game-evaluation-answer-message-0-7",
        // "game-evaluation-answer-message-0-8",
        // "game-evaluation-answer-message-0-9",
        // "game-evaluation-answer-message-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-answer-message-20-1",
        // "game-evaluation-answer-message-20-2",
        // "game-evaluation-answer-message-20-3",
        // "game-evaluation-answer-message-20-4",
        // "game-evaluation-answer-message-20-5",
        // "game-evaluation-answer-message-20-6",
        // "game-evaluation-answer-message-20-7",
        // "game-evaluation-answer-message-20-8",
        // "game-evaluation-answer-message-20-9",
        // "game-evaluation-answer-message-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-answer-message-40-1",
        // "game-evaluation-answer-message-40-2",
        // "game-evaluation-answer-message-40-3",
        // "game-evaluation-answer-message-40-4",
        // "game-evaluation-answer-message-40-5",
        // "game-evaluation-answer-message-40-6",
        // "game-evaluation-answer-message-40-7",
        // "game-evaluation-answer-message-40-8",
        // "game-evaluation-answer-message-40-9",
        // "game-evaluation-answer-message-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-answer-message-60-1",
        // "game-evaluation-answer-message-60-2",
        // "game-evaluation-answer-message-60-3",
        // "game-evaluation-answer-message-60-4",
        // "game-evaluation-answer-message-60-5",
        // "game-evaluation-answer-message-60-6",
        // "game-evaluation-answer-message-60-7",
        // "game-evaluation-answer-message-60-8",
        // "game-evaluation-answer-message-60-9",
        // "game-evaluation-answer-message-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-answer-message-80-1",
        // "game-evaluation-answer-message-80-2",
        // "game-evaluation-answer-message-80-3",
        // "game-evaluation-answer-message-80-4",
        // "game-evaluation-answer-message-80-5",
        // "game-evaluation-answer-message-80-6",
        // "game-evaluation-answer-message-80-7",
        // "game-evaluation-answer-message-80-8",
        // "game-evaluation-answer-message-80-9",
        // "game-evaluation-answer-message-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-answer-message-100-1",
        // "game-evaluation-answer-message-100-2",
        // "game-evaluation-answer-message-100-3",
        // "game-evaluation-answer-message-100-4",
        // "game-evaluation-answer-message-100-5",
        // "game-evaluation-answer-message-100-6",
        // "game-evaluation-answer-message-100-7",
        // "game-evaluation-answer-message-100-8",
        // "game-evaluation-answer-message-100-9",
        // "game-evaluation-answer-message-100-10"
      ]
    }
  ],
  "evaluation-answer-title": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-answer-title-0-1",
        // "game-evaluation-answer-title-0-2",
        // "game-evaluation-answer-title-0-3",
        // "game-evaluation-answer-title-0-4",
        // "game-evaluation-answer-title-0-5",
        // "game-evaluation-answer-title-0-6",
        // "game-evaluation-answer-title-0-7",
        // "game-evaluation-answer-title-0-8",
        // "game-evaluation-answer-title-0-9",
        // "game-evaluation-answer-title-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-answer-title-20-1",
        // "game-evaluation-answer-title-20-2",
        // "game-evaluation-answer-title-20-3",
        // "game-evaluation-answer-title-20-4",
        // "game-evaluation-answer-title-20-5",
        // "game-evaluation-answer-title-20-6",
        // "game-evaluation-answer-title-20-7",
        // "game-evaluation-answer-title-20-8",
        // "game-evaluation-answer-title-20-9",
        // "game-evaluation-answer-title-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-answer-title-40-1",
        // "game-evaluation-answer-title-40-2",
        // "game-evaluation-answer-title-40-3",
        // "game-evaluation-answer-title-40-4",
        // "game-evaluation-answer-title-40-5",
        // "game-evaluation-answer-title-40-6",
        // "game-evaluation-answer-title-40-7",
        // "game-evaluation-answer-title-40-8",
        // "game-evaluation-answer-title-40-9",
        // "game-evaluation-answer-title-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-answer-title-60-1",
        // "game-evaluation-answer-title-60-2",
        // "game-evaluation-answer-title-60-3",
        // "game-evaluation-answer-title-60-4",
        // "game-evaluation-answer-title-60-5",
        // "game-evaluation-answer-title-60-6",
        // "game-evaluation-answer-title-60-7",
        // "game-evaluation-answer-title-60-8",
        // "game-evaluation-answer-title-60-9",
        // "game-evaluation-answer-title-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-answer-title-80-1",
        // "game-evaluation-answer-title-80-2",
        // "game-evaluation-answer-title-80-3",
        // "game-evaluation-answer-title-80-4",
        // "game-evaluation-answer-title-80-5",
        // "game-evaluation-answer-title-80-6",
        // "game-evaluation-answer-title-80-7",
        // "game-evaluation-answer-title-80-8",
        // "game-evaluation-answer-title-80-9",
        // "game-evaluation-answer-title-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-answer-title-100-1",
        // "game-evaluation-answer-title-100-2",
        // "game-evaluation-answer-title-100-3",
        // "game-evaluation-answer-title-100-4",
        // "game-evaluation-answer-title-100-5",
        // "game-evaluation-answer-title-100-6",
        // "game-evaluation-answer-title-100-7",
        // "game-evaluation-answer-title-100-8",
        // "game-evaluation-answer-title-100-9",
        // "game-evaluation-answer-title-100-10"
      ]
    }
  ],
  "evaluation-play": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-play-message-0-1",
        // "game-evaluation-play-message-0-2",
        // "game-evaluation-play-message-0-3",
        // "game-evaluation-play-message-0-4",
        // "game-evaluation-play-message-0-5",
        // "game-evaluation-play-message-0-6",
        // "game-evaluation-play-message-0-7",
        // "game-evaluation-play-message-0-8",
        // "game-evaluation-play-message-0-9",
        // "game-evaluation-play-message-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-play-message-20-1",
        // "game-evaluation-play-message-20-2",
        // "game-evaluation-play-message-20-3",
        // "game-evaluation-play-message-20-4",
        // "game-evaluation-play-message-20-5",
        // "game-evaluation-play-message-20-6",
        // "game-evaluation-play-message-20-7",
        // "game-evaluation-play-message-20-8",
        // "game-evaluation-play-message-20-9",
        // "game-evaluation-play-message-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-play-message-40-1",
        // "game-evaluation-play-message-40-2",
        // "game-evaluation-play-message-40-3",
        // "game-evaluation-play-message-40-4",
        // "game-evaluation-play-message-40-5",
        // "game-evaluation-play-message-40-6",
        // "game-evaluation-play-message-40-7",
        // "game-evaluation-play-message-40-8",
        // "game-evaluation-play-message-40-9",
        // "game-evaluation-play-message-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-play-message-60-1",
        // "game-evaluation-play-message-60-2",
        // "game-evaluation-play-message-60-3",
        // "game-evaluation-play-message-60-4",
        // "game-evaluation-play-message-60-5",
        // "game-evaluation-play-message-60-6",
        // "game-evaluation-play-message-60-7",
        // "game-evaluation-play-message-60-8",
        // "game-evaluation-play-message-60-9",
        // "game-evaluation-play-message-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-play-message-80-1",
        // "game-evaluation-play-message-80-2",
        // "game-evaluation-play-message-80-3",
        // "game-evaluation-play-message-80-4",
        // "game-evaluation-play-message-80-5",
        // "game-evaluation-play-message-80-6",
        // "game-evaluation-play-message-80-7",
        // "game-evaluation-play-message-80-8",
        // "game-evaluation-play-message-80-9",
        // "game-evaluation-play-message-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-play-message-100-1",
        // "game-evaluation-play-message-100-2",
        // "game-evaluation-play-message-100-3",
        // "game-evaluation-play-message-100-4",
        // "game-evaluation-play-message-100-5",
        // "game-evaluation-play-message-100-6",
        // "game-evaluation-play-message-100-7",
        // "game-evaluation-play-message-100-8",
        // "game-evaluation-play-message-100-9",
        // "game-evaluation-play-message-100-10"
      ]
    }
  ],
  "evaluation-play-title": [
    { 
      "range": 0,
      "message": [
        "game-evaluation-play-title-0-1",
        // "game-evaluation-play-title-0-2",
        // "game-evaluation-play-title-0-3",
        // "game-evaluation-play-title-0-4",
        // "game-evaluation-play-title-0-5",
        // "game-evaluation-play-title-0-6",
        // "game-evaluation-play-title-0-7",
        // "game-evaluation-play-title-0-8",
        // "game-evaluation-play-title-0-9",
        // "game-evaluation-play-title-0-10"
      ]
    },
    { 
      "range": 20,
      "message": [
        "game-evaluation-play-title-20-1",
        // "game-evaluation-play-title-20-2",
        // "game-evaluation-play-title-20-3",
        // "game-evaluation-play-title-20-4",
        // "game-evaluation-play-title-20-5",
        // "game-evaluation-play-title-20-6",
        // "game-evaluation-play-title-20-7",
        // "game-evaluation-play-title-20-8",
        // "game-evaluation-play-title-20-9",
        // "game-evaluation-play-title-20-10"
      ]
    },
    { 
      "range": 40,
      "message": [
        "game-evaluation-play-title-40-1",
        // "game-evaluation-play-title-40-2",
        // "game-evaluation-play-title-40-3",
        // "game-evaluation-play-title-40-4",
        // "game-evaluation-play-title-40-5",
        // "game-evaluation-play-title-40-6",
        // "game-evaluation-play-title-40-7",
        // "game-evaluation-play-title-40-8",
        // "game-evaluation-play-title-40-9",
        // "game-evaluation-play-title-40-10"
      ]
    },
    { 
      "range": 60,
      "message": [
        "game-evaluation-play-title-60-1",
        // "game-evaluation-play-title-60-2",
        // "game-evaluation-play-title-60-3",
        // "game-evaluation-play-title-60-4",
        // "game-evaluation-play-title-60-5",
        // "game-evaluation-play-title-60-6",
        // "game-evaluation-play-title-60-7",
        // "game-evaluation-play-title-60-8",
        // "game-evaluation-play-title-60-9",
        // "game-evaluation-play-title-60-10"
      ]
    },
    { 
      "range": 80,
      "message": [
        "game-evaluation-play-title-80-1",
        // "game-evaluation-play-title-80-2",
        // "game-evaluation-play-title-80-3",
        // "game-evaluation-play-title-80-4",
        // "game-evaluation-play-title-80-5",
        // "game-evaluation-play-title-80-6",
        // "game-evaluation-play-title-80-7",
        // "game-evaluation-play-title-80-8",
        // "game-evaluation-play-title-80-9",
        // "game-evaluation-play-title-80-10"
      ]
    },
    { 
      "range": 100,
      "message": [
        "game-evaluation-play-title-100-1",
        // "game-evaluation-play-title-100-2",
        // "game-evaluation-play-title-100-3",
        // "game-evaluation-play-title-100-4",
        // "game-evaluation-play-title-100-5",
        // "game-evaluation-play-title-100-6",
        // "game-evaluation-play-title-100-7",
        // "game-evaluation-play-title-100-8",
        // "game-evaluation-play-title-100-9",
        // "game-evaluation-play-title-100-10"
      ]
    }
  ]
}
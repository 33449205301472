// 전역 변수
export const globalVariables = {

  app: {} as any,

  /*
  ** 디폴트
  */
  token: {
    server: '',                                       // 서버사이드 가상 토큰
    gap: 10000                                        // 토큰 시간차 강제 지정 ( 프론트가 백엔드보다 이 시간만큼 길게 가진다)
  },
  authemail: {
    // noreply_email: 'no-reply@musicmatch.world',    // 발신전용 이메일
    noreply_email: 'musicmatch.team@gmail.com',       // 발신전용 이메일 ( 실제 메일 )
    expire: 600000                                    // 이메일 인증 만료 시간 ( 초 )
  },
  api: {
    timer: null as any,                               // 인터발에 사용할 변수
    interval: 100,                                    // 재시도 인터발
    timeout: 1000,                                    // API 연결 최대 시간
    searchInterval: 300,                              // 검색 인터발
    autoCompleteInterval: 3000                        // 자동완성 검색 인터발
  },
  popstate: {
    key: 'sp-storage-rendering-',                     // 히스토리 키
    gap: 100                                          // 뒤로가기 체크용 시간
  },
  resolution: {
    font: 16,                                         // 현재 폰트 사이즈
    fonts: [16, 15, 14, 13, 12],                      // 사이즈별 폰트 사이즈
    pc: 1440,                                         // PC 사이즈
    notebook: 1279,                                   // 노트북 사이즈
    tablet: 1023,                                     // 테블릿 사이즈
    mobile: 767,                                      // 모바일 사이즈
    min: 420,                                         // 최소 사이즈
    width: 0,                                         // 현재 브라우저 가로 사이즈
    height: 0,                                        // 현재 브라우저 세로 사이즈
    scroll: 0
  },
  pagination: {
    path: '/',                                        // 현재 페이지의 path 특정 ( 목록 페이지 바뀌면 이것도 바꿔줘야 함 )
    total: 1,                                         // 총 아이템 수 ( api )
    page: 1,                                          // 현재 페이지
    size: 0,                                          // 페이지당 표현 수 (변수)
    colsize: 12,                                      // 가로형 표현 수
    rowsize: 6,                                       // 세로형 표현 수
    count: 1,                                         // 총 페이지 수 ( api )
    timeout: 200,                                     // 뒤로, 앞으로 API 호출 딜레이
    keyword: '',                                      // 검색 키워드
    delayTimeout: null as any,                        // 딜레이 이벤트 리스너
    pagestamp: null as any,                           // 마지막으로 페이지를 클릭한 시간
    apistamp: null as any                             // 마지막으로 클릭한 시간
  } as any,

  // 환경변수
  experience: {
    hit: 60 * 10 * 1000                               // 조회수를 올리는 시간
  },

  /* -------------------------------------------------------------------------------------------
  ** 게임 규칙
  */
  melody: {
    step: 1,                                          // 퀴즈 현재 힌트 단계
    stepMax: 5,                                       // 최대 힌트 단계
    repeatMax: 3,                                     // 다시듣기 최대 수
    answerChance: 2,                                  // 답변 제출 제한
    playTime: [0, 1, 2, 3, 4, 5],                     // 음악 재생 시간
    previewTime: 7,                                   // 음악 미리듣기 ( 스트리밍 모드 )
    fadeOutTime: 3,                                   // 음악 종료 후 사라지는 텀
    volume: 80,                                       // 기본 볼륨 설정
    disconnect: 30000                                 // 강제 연결 종료 ( 10초로 한다. )
  },
  melody_hard: {
    step: 1,                                          // 퀴즈 현재 힌트 단계
    stepMax: 3,                                       // 최대 힌트 단계
    repeatMax: 2,                                     // 다시듣기 최대 수
    answerChance: 1,                                  // 답변 제출 제한
    playTime: [0, 1, 2, 3],                           // 음악 재생 시간
    previewTime: 7,                                   // 음악 미리듣기 ( 스트리밍 모드 )
    fadeOutTime: 3,                                   // 음악 종료 후 사라지는 텀
    volume: 80,                                       // 기본 볼륨 설정
    disconnect: 30000                                 // 강제 연결 종료 ( 10초로 한다. )
  },
  melody_very_hard: {
    step: 1,                                          // 퀴즈 현재 힌트 단계
    stepMax: 1,                                       // 최대 힌트 단계
    repeatMax: 1,                                     // 다시듣기 최대 수
    answerChance: 0,                                  // 답변 제출 제한
    playTime: [0, 1],                                 // 음악 재생 시간
    previewTime: 7,                                   // 음악 미리듣기 ( 스트리밍 모드 )
    fadeOutTime: 3,                                   // 음악 종료 후 사라지는 텀
    volume: 80,                                       // 기본 볼륨 설정
    disconnect: 30000                                 // 강제 연결 종료 ( 10초로 한다. )
  },

  /* -------------------------------------------------------------------------------------------
  ** 게임 가중치
  */
  melody_weight: {
    playDefaultSecond: 50,                            // 문제 풀이 시간
    maxScore: 1000000,                                // 맥시멈 점수 ( 가상 )
    correctWeight: .95,                               // 정답 가중치
    playWeight: .01,                                  // 플레이 시간 가중치
    hintWeight: .02,                                  // 힌트 듣기 가중치
    repeatWeight: .01,                                // 다시 듣시 가중치
    answerWeight: .01                                 // 오답 제출 가중치
  },
  melody_weight_hard: {
    playDefaultSecond: 30,                            // 문제 풀이 시간
    maxScore: 1000000,                                // 맥시멈 점수 ( 가상 )
    correctWeight: .95,                               // 정답 가중치
    playWeight: .01,                                  // 플레이 시간 가중치
    hintWeight: .02,                                  // 힌트 듣기 가중치
    repeatWeight: .01,                                // 다시 듣시 가중치
    answerWeight: .01                                 // 오답 제출 가중치
  },
  melody_weight_very_hard: {
    playDefaultSecond: 20,                            // 문제 풀이 시간
    maxScore: 1000000,                                // 맥시멈 점수 ( 가상 )
    correctWeight: .95,                               // 정답 가중치
    playWeight: .01,                                  // 플레이 시간 가중치
    hintWeight: .02,                                  // 힌트 듣기 가중치
    repeatWeight: .01,                                // 다시 듣시 가중치
    answerWeight: .01                                 // 오답 제출 가중치
  },

  /*
  ** 시간 규칙
  */
  time: {
    new: 86400000,                                     // 새 컨텐츠 허용 범위 ( ms )
    icon: 'new'
  },

  /*
  ** 코멘트 규칙
  */
  comments: {
    min: 0,
    max: 200,
    reviewCount: 5                                    // 게임하기에서 코멘트가 미리 보이는 수
  },

  /*
  ** 퀴즈 생성 정의
  */
  create: {
    errorInterval: 3000,                              // 오류를 표시하는 시간
    search: {
      max: 50                                         // 검색 키워드 최대 길이
    },
    url: {
      max: 100                                        // 유튜브 주소 최대 길이
    },
    nickname: {
      min: 2,                                         // 닉네임 최소 글자 수
      max: 25                                         // 닉네임 최대 글자 수
    },
    password: {
      min: 10,                                        // 비밀번호 최소 글자 수
      max: 30                                         // 비밀번호 최대 글자 수
    },
    song: {
      min: 3,                                         // 퀴즈당 최소 문제 수
      max: 15                                         // 퀴즈당 최대 문제 수
    },
    title: {
      min: 2,                                         // 제목 최소 글자 수
      max: 50                                         // 제목 최대 글자 수
    },
    description: {
      min: 2,                                         // 설명 최소 글자 수
      max: 200,                                       // 설명 최대 글자 수
      rows: 6                                         // 설명 rows
    },
    genre: {
      max: 3                                          // 장르 최대 선택 수
    },
    hashtag: {
      max: 5                                          // 해시태그 최대 입력 수
    },
    year: {
      min: new Date().getFullYear() - 60,             // 년도 최소 ( 최대 80년 )
      max: new Date().getFullYear(),                  // 년도 최대
      leeds: 10,                                      // 리즈 연령 설정 ( 몇살부터 음악을 듣는지 )
      minLeeds: new Date().getFullYear() - 20,        // 기본 설정 연도 ( min )
      maxLeeds: new Date().getFullYear() - 5,         // 기본 설정 연도 ( max )
      step: 1,                                        // 수치 스탭
      tickStep: 10                                    // 틱 스탭
    },
    thumbnail: {
      size: 2000000,                                  // 허용 용량
      sizeText: '2MB',                                // 용량 숏컷
      file: ['jpg', 'jpeg', 'png', 'gif', 'webp']     // 허용하는 확장자
    },
    request: {
      artist: {
        min: 0,
        max: 50
      },
      content: {
        min: 0,
        max: 100
      }
    }
  },

  /*
  ** 유저셋
  */
  userset: {
    color: 'purple-darken-1'
  },

  /*
  ** 기본 에이전트
  ** 빌드할 땐 한글이 기본
  */
  userAgent: 'ko-KR',

  /*
  ** 언어 정의
  */
  language: [
    { code: 'ko', text: '한국어' },
    { code: 'en', text: 'English' },
    { code: 'jp', text: '日本語' },
    { code: 'cn', text: '中文' }
  ],

  /*
  ** 국가 정의
  */
  country: [
    { code: 'KR', text: '' },
    { code: 'US', text: '' },
    { code: 'JP', text: '' },
    { code: 'CN', text: '' }
  ],

  /*
  ** 장르 정의
  */
  genre: [
    { code: 'pop', text: '' },
    { code: 'kpop', text: '' },
    { code: 'hiphop', text: '' },
    { code: 'ballade', text: '' },
    { code: 'rock', text: '' },
    { code: 'trot', text: '' },
    { code: 'rnb', text: '' },
    { code: 'folk', text: '' },
    { code: 'reggae', text: '' },
    { code: 'classic', text: '' },
    { code: 'jazz', text: '' },
    { code: 'blues', text: '' },
    { code: 'electronic', text: '' },
    { code: 'country', text: '' },
    { code: 'disco', text: '' },
    { code: 'ccm', text: '' },
    { code: 'etc', text: '' }
  ],

  /*
  ** 연도, 연령 정의
  */
  year: [] as any,

  /*
  ** 연령대 정의
  */
  age: [
    { code: 10, text: '' },
    { code: 20, text: '' },
    { code: 30, text: '' },
    { code: 40, text: '' },
    { code: 50, text: '' },
    { code: 60, text: '' }
  ],

  /*
  ** 회원가입
  */
  auth: {
    secureClass: [
      'red-darken-1',
      'orange-darken-1',
      'yellow-darken-1',
      'teal-lighten-1',
      'green-lighten-1'
    ]
  },

  /*
  ** 스트리밍 데이터
  */
  streaming: [
    { 'name': '🎹 유튜브 뮤직 바로가기', 'url': 'https://music.youtube.com/?keyword=', 'image': '' , 'color': 'primary' },
    { 'name': '스포티파이 바로가기', 'url': 'https://open.spotify.com/search/', 'image': '', 'color': 'secondary'  },
    { 'name': '애플뮤직', 'url': 'https://music.apple.com/us/search?term=', 'image': '', 'color': ''  },
    { 'name': '벅스뮤직', 'url': 'https://music.bugs.co.kr/search/integrated?q=', 'image': '' , 'color': '' },
    { 'name': '지니뮤직', 'url': 'https://www.genie.co.kr/search/searchMain?query=', 'image': '' , 'color': '' },
    { 'name': '소리바다', 'url': 'https://www.soribada.com/', 'image': '' , 'color': '' },
    { 'name': '플로', 'url': 'https://www.music-flo.com/search/all?keyword=', 'image': '' , 'color': '' },
    { 'name': '멜론', 'url': 'https://www.melon.com/search/total/index.htm?q=', 'image': '', 'color': ''  }
  ],

  /*
  ** 기타 정의
  */
  socials: [
    { name: 'Youtube', icon: 'mdi-youtube', color: 'md', url: 'https://www.youtube.com/@musicmatch-world' },
    { name: 'Instagram', icon: 'mdi-instagram', color: 'md', url: '#none' },
    { name: 'Twitter(X)', icon: 'mdi-alpha-x-circle', color: 'md', url: '#none' }
  ],
  hook: {
    save: [                                                   // 이전 페이지를 기록할 페이지 ( 외부 페이지 X )
      'auth-login',
      'auth-join'
    ],
    auth: [                                                   // 로그인 후 접속이 불가능한 페이지
      'auth-login',
      'auth-join',
      'auth-complete',
      'auth-complete-verification',
      'auth-login-callback-naver-login',
      'auth-login-callback-kakaotalk-login',
      'auth-find',
      'auth-found',
      'auth-found-change',
      'auth-found-changed',
      'auth-login-callback-naver-login',
      'auth-login-callback-kakaotalk-login'
    ],
    private: [                                                // 로그인 전 접속이 불가능한 페이지
      'me',
      'me-game',
      'me-comment',
      'me-modify',
      'me-modify-verified',
      'me-records',
      'game-melody-create',
      'game-melody-modify',
    ]
  },
  rankBadges: [ '🏆', '🥈', '🥉' ],
  mainMenu: {
    login: [
      { type: 'subheader', title: 'GAMES' },
      { text: 'create-game', title: '', icon: 'mdi-gamepad-outline', color: "secondary", path: '/game/melody/create' },
      { type: 'divider' },
      { type: 'subheader', title: 'MEMBER' },
      { text: 'my-info', title: '', icon: 'mdi-account-box', path: '/me' },
      { text: 'my-records', title: '', icon: 'mdi-music', path: '/me/records' },
      { type: 'divider' },
      { text: 'log-out', title: '', icon: 'mdi-power', event: () => globalFunctions.logOut() },
      { type: 'block' },
      { type: 'subheader', title: 'MUSICMATCH' },
      { text: 'board-notice', title: '', icon: 'mdi-list-box', path: '/board/notice' },
      { text: 'game-melody-report-best', title: '', icon: 'mdi-crown', path: '/game/melody/report' },
      { text: 'game-melody-report-comment', title: '', icon: 'mdi-comment-processing-outline', path: '/game/melody/report/comment' },
      { text: 'game-melody-report-playing', title: '', icon: 'mdi-gamepad-circle-right', path: '/game/melody/report/playing' }
    ],

    // "game-melody-report-best": "베스트 100",
    // "game-melody-report-comment": "최근 코멘트",
    // "game-melody-report-playing": "진행중인 게임",

    logout: [
      { type: 'subheader', title: 'MEMBER' },
      { text: 'log-in', title: '', icon: 'mdi-account-circle-outline', path: '/auth/login' },
      { text: 'sign-up', title: '', icon: 'mdi-plus', path: '/auth/join' },
      { type: 'divider' },
      { text: 'find-account', title: '', icon: 'mdi-account-box', path: '/auth/find' },
      { text: 'sign-up', title: '', icon: 'mdi-plus', path: '/auth/join' },
      { type: 'block' },
      { type: 'subheader', title: 'MUSICMATCH' },
      { text: 'board-notice', title: '', icon: 'mdi-list-box', path: '/board/notice' },
      { text: 'game-melody-report-best', title: '', icon: 'mdi-crown', path: '/game/melody/report' },
      { text: 'game-melody-report-comment', title: '', icon: 'mdi-comment-processing-outline', path: '/game/melody/report/comment' },
      { text: 'game-melody-report-playing', title: '', icon: 'mdi-gamepad-circle-right', path: '/game/melody/report/playing' },
      { type: 'divider' },
      { type: 'subheader', title: 'GAMES' },
      { text: 'create-game', title: '', icon: 'mdi-gamepad-outline', color: "secondary", path: '/game/melody/create' },
      { text: 'agreement', title: '', icon: 'mdi-help-circle-outline', path: '/our/agreement' }
    ]
  },
  footerMenu: {
    login: [
      { text: 'my-info', icon: 'mdi-account-box', path: '/me' },
      { text: 'my-records', icon: 'mdi-music', path: '/me/records' }
    ],
    logout: [
      { text: 'log-in', icon: 'mdi-account-circle-outline', path: '/auth/login' },
      { text: 'sign-up', icon: 'mdi-plus', path: '/auth/join' }
    ]
  },
  gameMelodyStatisticsLnb: {
    tabs: 0,
    items: [
      { value: 0, key: 'game-melody-statistics-id', text: 'game-stastics', title: '', icon: 'mdi-flag', url: `/game/melody/statistics/{#id}`, selected: false },
      { value: 1, key: 'game-melody-statistics-id-quiz', text: 'game-quiz-list', title: '', icon: 'mdi-playlist-music', url: `/game/melody/statistics/{#id}/quiz`, selected: false },
      { value: 2, key: 'game-melody-statistics-id-rank', text: 'game-player-ranking', title: '', icon: 'mdi-chart-box', url: `/game/melody/statistics/{#id}/rank`, selected: false },
      { value: 3, key: 'game-melody-statistics-id-comment', text: 'game-melody-comment', title: '', icon: 'mdi-comment-processing-outline', url: `/game/melody/statistics/{#id}/comment`, selected: false }
    ]
  },
  meLnb: {
    tabs: 0,
    items: [
      { value: 0, key: 'me', text: 'my-info', title: '', icon: 'mdi-home-account', url: `/me`, selected: false },
      { value: 1, key: 'me-records', text: 'my-records', title: '', icon: 'mdi-music', url: `/me/records`, selected: false },
      { value: 2, key: 'me-game', text: 'me-game', title: '', icon: 'mdi-gamepad-outline', url: `/me/game`, selected: false },
      { value: 3, key: 'me-comment', text: 'game-melody-me', title: '', icon: 'mdi-comment-processing-outline', url: `/me/comment`, selected: false },
    ]
  },

  // 유튜브 API
  youtube: {
    apiKey: [
      'AIzaSyB28kC62TZpgDiZHjJbDtx0XpjbO3XYKzg'
    ],
    maxResults: 3
  },

  /*
  ** 파이어베이스
  */
  firebase: {
    auth: null,
    user: null
  } as any
}
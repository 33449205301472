import plugin_vue3_A0OWXRrUgq from "D:/VsCode/_music/web-staging-2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "D:/VsCode/_music/web-staging-2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "D:/VsCode/_music/web-staging-2/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "D:/VsCode/_music/web-staging-2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/VsCode/_music/web-staging-2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "D:/VsCode/_music/web-staging-2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "D:/VsCode/_music/web-staging-2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import token_NCWuir2H2e from "D:/VsCode/_music/web-staging-2/plugins/token.ts";
import index_ZtK95fJDTK from "D:/VsCode/_music/web-staging-2/plugins/index.ts";
import router_WMnTGednOQ from "D:/VsCode/_music/web-staging-2/plugins/router.ts";
import i18n_VfGcjrvSkj from "D:/VsCode/_music/web-staging-2/plugins/i18n.ts";
import firebase_Wjz9XrZo01 from "D:/VsCode/_music/web-staging-2/plugins/firebase.ts";
import vuetify_7h9QAQEssH from "D:/VsCode/_music/web-staging-2/plugins/vuetify.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  token_NCWuir2H2e,
  index_ZtK95fJDTK,
  router_WMnTGednOQ,
  i18n_VfGcjrvSkj,
  firebase_Wjz9XrZo01,
  vuetify_7h9QAQEssH
]
import { default as indexo7fT7jVo7NMeta } from "D:/VsCode/_music/web-staging-2/pages/auth/complete/index.vue?macro=true";
import { default as verificationpxhiBJxVKMMeta } from "D:/VsCode/_music/web-staging-2/pages/auth/complete/verification.vue?macro=true";
import { default as indexmjeiXxIROJMeta } from "D:/VsCode/_music/web-staging-2/pages/auth/find/index.vue?macro=true";
import { default as change16LsAO5LM7Meta } from "D:/VsCode/_music/web-staging-2/pages/auth/found/change.vue?macro=true";
import { default as changed4dLJiytmWBMeta } from "D:/VsCode/_music/web-staging-2/pages/auth/found/changed.vue?macro=true";
import { default as index8vHGdlQGkDMeta } from "D:/VsCode/_music/web-staging-2/pages/auth/found/index.vue?macro=true";
import { default as indexoCsB6YKcpmMeta } from "D:/VsCode/_music/web-staging-2/pages/auth/join/index.vue?macro=true";
import { default as callback_45kakaotalk_45loginSAsngYhAaGMeta } from "D:/VsCode/_music/web-staging-2/pages/auth/login/callback-kakaotalk-login.vue?macro=true";
import { default as callback_45naver_45loginx5oTxP1IzWMeta } from "D:/VsCode/_music/web-staging-2/pages/auth/login/callback-naver-login.vue?macro=true";
import { default as indexya6Fx63ncOMeta } from "D:/VsCode/_music/web-staging-2/pages/auth/login/index.vue?macro=true";
import { default as indextTdeH1i6d6Meta } from "D:/VsCode/_music/web-staging-2/pages/board/[code]/[id]/index.vue?macro=true";
import { default as indexP2stc9zj34Meta } from "D:/VsCode/_music/web-staging-2/pages/board/[code]/index.vue?macro=true";
import { default as indexikdIQQSqTdMeta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/[id]/index.vue?macro=true";
import { default as createWcdhq4jSGIMeta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/create.vue?macro=true";
import { default as modifygwvDry8NPiMeta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/modify.vue?macro=true";
import { default as indexETcHPlUGpXMeta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/report/comment/index.vue?macro=true";
import { default as index4qLK29OHlfMeta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/report/index.vue?macro=true";
import { default as index1CGXoG9CP2Meta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/report/playing/index.vue?macro=true";
import { default as indexjF5gUpYKuPMeta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/statistics/[id]/comment/index.vue?macro=true";
import { default as indexqMSNUzC6ARMeta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/statistics/[id]/index.vue?macro=true";
import { default as indexQVRk8sudU4Meta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/statistics/[id]/quiz/index.vue?macro=true";
import { default as indexAMaSVbDRt2Meta } from "D:/VsCode/_music/web-staging-2/pages/game/melody/statistics/[id]/rank/index.vue?macro=true";
import { default as indexhWrsbVPY3VMeta } from "D:/VsCode/_music/web-staging-2/pages/index.vue?macro=true";
import { default as indexMREWwtk3RfMeta } from "D:/VsCode/_music/web-staging-2/pages/me/comment/index.vue?macro=true";
import { default as indexdWxn9eutHrMeta } from "D:/VsCode/_music/web-staging-2/pages/me/game/index.vue?macro=true";
import { default as indexSA1lXhMvA1Meta } from "D:/VsCode/_music/web-staging-2/pages/me/index.vue?macro=true";
import { default as indexXzfr3ltuEOMeta } from "D:/VsCode/_music/web-staging-2/pages/me/modify/index.vue?macro=true";
import { default as verifiedJIbVZz1lz7Meta } from "D:/VsCode/_music/web-staging-2/pages/me/modify/verified.vue?macro=true";
import { default as index5aLtvNZ2g1Meta } from "D:/VsCode/_music/web-staging-2/pages/me/records/index.vue?macro=true";
import { default as indexw6RzzhewtTMeta } from "D:/VsCode/_music/web-staging-2/pages/our/agreement/index.vue?macro=true";
import { default as indexujUdXF5Oo6Meta } from "D:/VsCode/_music/web-staging-2/pages/our/index.vue?macro=true";
import { default as indexgY4X5KwS8QMeta } from "D:/VsCode/_music/web-staging-2/pages/our/privacy/index.vue?macro=true";
export default [
  {
    name: indexo7fT7jVo7NMeta?.name ?? "auth-complete",
    path: indexo7fT7jVo7NMeta?.path ?? "/auth/complete",
    meta: indexo7fT7jVo7NMeta || {},
    alias: indexo7fT7jVo7NMeta?.alias || [],
    redirect: indexo7fT7jVo7NMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/complete/index.vue").then(m => m.default || m)
  },
  {
    name: verificationpxhiBJxVKMMeta?.name ?? "auth-complete-verification",
    path: verificationpxhiBJxVKMMeta?.path ?? "/auth/complete/verification",
    meta: verificationpxhiBJxVKMMeta || {},
    alias: verificationpxhiBJxVKMMeta?.alias || [],
    redirect: verificationpxhiBJxVKMMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/complete/verification.vue").then(m => m.default || m)
  },
  {
    name: indexmjeiXxIROJMeta?.name ?? "auth-find",
    path: indexmjeiXxIROJMeta?.path ?? "/auth/find",
    meta: indexmjeiXxIROJMeta || {},
    alias: indexmjeiXxIROJMeta?.alias || [],
    redirect: indexmjeiXxIROJMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/find/index.vue").then(m => m.default || m)
  },
  {
    name: change16LsAO5LM7Meta?.name ?? "auth-found-change",
    path: change16LsAO5LM7Meta?.path ?? "/auth/found/change",
    meta: change16LsAO5LM7Meta || {},
    alias: change16LsAO5LM7Meta?.alias || [],
    redirect: change16LsAO5LM7Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/found/change.vue").then(m => m.default || m)
  },
  {
    name: changed4dLJiytmWBMeta?.name ?? "auth-found-changed",
    path: changed4dLJiytmWBMeta?.path ?? "/auth/found/changed",
    meta: changed4dLJiytmWBMeta || {},
    alias: changed4dLJiytmWBMeta?.alias || [],
    redirect: changed4dLJiytmWBMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/found/changed.vue").then(m => m.default || m)
  },
  {
    name: index8vHGdlQGkDMeta?.name ?? "auth-found",
    path: index8vHGdlQGkDMeta?.path ?? "/auth/found",
    meta: index8vHGdlQGkDMeta || {},
    alias: index8vHGdlQGkDMeta?.alias || [],
    redirect: index8vHGdlQGkDMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/found/index.vue").then(m => m.default || m)
  },
  {
    name: indexoCsB6YKcpmMeta?.name ?? "auth-join",
    path: indexoCsB6YKcpmMeta?.path ?? "/auth/join",
    meta: indexoCsB6YKcpmMeta || {},
    alias: indexoCsB6YKcpmMeta?.alias || [],
    redirect: indexoCsB6YKcpmMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/join/index.vue").then(m => m.default || m)
  },
  {
    name: callback_45kakaotalk_45loginSAsngYhAaGMeta?.name ?? "auth-login-callback-kakaotalk-login",
    path: callback_45kakaotalk_45loginSAsngYhAaGMeta?.path ?? "/auth/login/callback-kakaotalk-login",
    meta: callback_45kakaotalk_45loginSAsngYhAaGMeta || {},
    alias: callback_45kakaotalk_45loginSAsngYhAaGMeta?.alias || [],
    redirect: callback_45kakaotalk_45loginSAsngYhAaGMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/login/callback-kakaotalk-login.vue").then(m => m.default || m)
  },
  {
    name: callback_45naver_45loginx5oTxP1IzWMeta?.name ?? "auth-login-callback-naver-login",
    path: callback_45naver_45loginx5oTxP1IzWMeta?.path ?? "/auth/login/callback-naver-login",
    meta: callback_45naver_45loginx5oTxP1IzWMeta || {},
    alias: callback_45naver_45loginx5oTxP1IzWMeta?.alias || [],
    redirect: callback_45naver_45loginx5oTxP1IzWMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/login/callback-naver-login.vue").then(m => m.default || m)
  },
  {
    name: indexya6Fx63ncOMeta?.name ?? "auth-login",
    path: indexya6Fx63ncOMeta?.path ?? "/auth/login",
    meta: indexya6Fx63ncOMeta || {},
    alias: indexya6Fx63ncOMeta?.alias || [],
    redirect: indexya6Fx63ncOMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: indextTdeH1i6d6Meta?.name ?? "board-code-id",
    path: indextTdeH1i6d6Meta?.path ?? "/board/:code()/:id()",
    meta: indextTdeH1i6d6Meta || {},
    alias: indextTdeH1i6d6Meta?.alias || [],
    redirect: indextTdeH1i6d6Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/board/[code]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexP2stc9zj34Meta?.name ?? "board-code",
    path: indexP2stc9zj34Meta?.path ?? "/board/:code()",
    meta: indexP2stc9zj34Meta || {},
    alias: indexP2stc9zj34Meta?.alias || [],
    redirect: indexP2stc9zj34Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/board/[code]/index.vue").then(m => m.default || m)
  },
  {
    name: indexikdIQQSqTdMeta?.name ?? "game-melody-id",
    path: indexikdIQQSqTdMeta?.path ?? "/game/melody/:id()",
    meta: indexikdIQQSqTdMeta || {},
    alias: indexikdIQQSqTdMeta?.alias || [],
    redirect: indexikdIQQSqTdMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createWcdhq4jSGIMeta?.name ?? "game-melody-create",
    path: createWcdhq4jSGIMeta?.path ?? "/game/melody/create",
    meta: createWcdhq4jSGIMeta || {},
    alias: createWcdhq4jSGIMeta?.alias || [],
    redirect: createWcdhq4jSGIMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/create.vue").then(m => m.default || m)
  },
  {
    name: modifygwvDry8NPiMeta?.name ?? "game-melody-modify",
    path: modifygwvDry8NPiMeta?.path ?? "/game/melody/modify",
    meta: modifygwvDry8NPiMeta || {},
    alias: modifygwvDry8NPiMeta?.alias || [],
    redirect: modifygwvDry8NPiMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/modify.vue").then(m => m.default || m)
  },
  {
    name: indexETcHPlUGpXMeta?.name ?? "game-melody-report-comment",
    path: indexETcHPlUGpXMeta?.path ?? "/game/melody/report/comment",
    meta: indexETcHPlUGpXMeta || {},
    alias: indexETcHPlUGpXMeta?.alias || [],
    redirect: indexETcHPlUGpXMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/report/comment/index.vue").then(m => m.default || m)
  },
  {
    name: index4qLK29OHlfMeta?.name ?? "game-melody-report",
    path: index4qLK29OHlfMeta?.path ?? "/game/melody/report",
    meta: index4qLK29OHlfMeta || {},
    alias: index4qLK29OHlfMeta?.alias || [],
    redirect: index4qLK29OHlfMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/report/index.vue").then(m => m.default || m)
  },
  {
    name: index1CGXoG9CP2Meta?.name ?? "game-melody-report-playing",
    path: index1CGXoG9CP2Meta?.path ?? "/game/melody/report/playing",
    meta: index1CGXoG9CP2Meta || {},
    alias: index1CGXoG9CP2Meta?.alias || [],
    redirect: index1CGXoG9CP2Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/report/playing/index.vue").then(m => m.default || m)
  },
  {
    name: indexjF5gUpYKuPMeta?.name ?? "game-melody-statistics-id-comment",
    path: indexjF5gUpYKuPMeta?.path ?? "/game/melody/statistics/:id()/comment",
    meta: indexjF5gUpYKuPMeta || {},
    alias: indexjF5gUpYKuPMeta?.alias || [],
    redirect: indexjF5gUpYKuPMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/statistics/[id]/comment/index.vue").then(m => m.default || m)
  },
  {
    name: indexqMSNUzC6ARMeta?.name ?? "game-melody-statistics-id",
    path: indexqMSNUzC6ARMeta?.path ?? "/game/melody/statistics/:id()",
    meta: indexqMSNUzC6ARMeta || {},
    alias: indexqMSNUzC6ARMeta?.alias || [],
    redirect: indexqMSNUzC6ARMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/statistics/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQVRk8sudU4Meta?.name ?? "game-melody-statistics-id-quiz",
    path: indexQVRk8sudU4Meta?.path ?? "/game/melody/statistics/:id()/quiz",
    meta: indexQVRk8sudU4Meta || {},
    alias: indexQVRk8sudU4Meta?.alias || [],
    redirect: indexQVRk8sudU4Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/statistics/[id]/quiz/index.vue").then(m => m.default || m)
  },
  {
    name: indexAMaSVbDRt2Meta?.name ?? "game-melody-statistics-id-rank",
    path: indexAMaSVbDRt2Meta?.path ?? "/game/melody/statistics/:id()/rank",
    meta: indexAMaSVbDRt2Meta || {},
    alias: indexAMaSVbDRt2Meta?.alias || [],
    redirect: indexAMaSVbDRt2Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/game/melody/statistics/[id]/rank/index.vue").then(m => m.default || m)
  },
  {
    name: indexhWrsbVPY3VMeta?.name ?? "index",
    path: indexhWrsbVPY3VMeta?.path ?? "/",
    meta: indexhWrsbVPY3VMeta || {},
    alias: indexhWrsbVPY3VMeta?.alias || [],
    redirect: indexhWrsbVPY3VMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexMREWwtk3RfMeta?.name ?? "me-comment",
    path: indexMREWwtk3RfMeta?.path ?? "/me/comment",
    meta: indexMREWwtk3RfMeta || {},
    alias: indexMREWwtk3RfMeta?.alias || [],
    redirect: indexMREWwtk3RfMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/me/comment/index.vue").then(m => m.default || m)
  },
  {
    name: indexdWxn9eutHrMeta?.name ?? "me-game",
    path: indexdWxn9eutHrMeta?.path ?? "/me/game",
    meta: indexdWxn9eutHrMeta || {},
    alias: indexdWxn9eutHrMeta?.alias || [],
    redirect: indexdWxn9eutHrMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/me/game/index.vue").then(m => m.default || m)
  },
  {
    name: indexSA1lXhMvA1Meta?.name ?? "me",
    path: indexSA1lXhMvA1Meta?.path ?? "/me",
    meta: indexSA1lXhMvA1Meta || {},
    alias: indexSA1lXhMvA1Meta?.alias || [],
    redirect: indexSA1lXhMvA1Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: indexXzfr3ltuEOMeta?.name ?? "me-modify",
    path: indexXzfr3ltuEOMeta?.path ?? "/me/modify",
    meta: indexXzfr3ltuEOMeta || {},
    alias: indexXzfr3ltuEOMeta?.alias || [],
    redirect: indexXzfr3ltuEOMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/me/modify/index.vue").then(m => m.default || m)
  },
  {
    name: verifiedJIbVZz1lz7Meta?.name ?? "me-modify-verified",
    path: verifiedJIbVZz1lz7Meta?.path ?? "/me/modify/verified",
    meta: verifiedJIbVZz1lz7Meta || {},
    alias: verifiedJIbVZz1lz7Meta?.alias || [],
    redirect: verifiedJIbVZz1lz7Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/me/modify/verified.vue").then(m => m.default || m)
  },
  {
    name: index5aLtvNZ2g1Meta?.name ?? "me-records",
    path: index5aLtvNZ2g1Meta?.path ?? "/me/records",
    meta: index5aLtvNZ2g1Meta || {},
    alias: index5aLtvNZ2g1Meta?.alias || [],
    redirect: index5aLtvNZ2g1Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/me/records/index.vue").then(m => m.default || m)
  },
  {
    name: indexw6RzzhewtTMeta?.name ?? "our-agreement",
    path: indexw6RzzhewtTMeta?.path ?? "/our/agreement",
    meta: indexw6RzzhewtTMeta || {},
    alias: indexw6RzzhewtTMeta?.alias || [],
    redirect: indexw6RzzhewtTMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/our/agreement/index.vue").then(m => m.default || m)
  },
  {
    name: indexujUdXF5Oo6Meta?.name ?? "our",
    path: indexujUdXF5Oo6Meta?.path ?? "/our",
    meta: indexujUdXF5Oo6Meta || {},
    alias: indexujUdXF5Oo6Meta?.alias || [],
    redirect: indexujUdXF5Oo6Meta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/our/index.vue").then(m => m.default || m)
  },
  {
    name: indexgY4X5KwS8QMeta?.name ?? "our-privacy",
    path: indexgY4X5KwS8QMeta?.path ?? "/our/privacy",
    meta: indexgY4X5KwS8QMeta || {},
    alias: indexgY4X5KwS8QMeta?.alias || [],
    redirect: indexgY4X5KwS8QMeta?.redirect || undefined,
    component: () => import("D:/VsCode/_music/web-staging-2/pages/our/privacy/index.vue").then(m => m.default || m)
  }
]
import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => ({
    isClient: ref(false),
    error: ref(false),
    errorMsg: ref(''),
    loaderRouter: ref(false),
    loaderPage: ref(false),
    loader: ref(false),
    layout: ref({
      name: '',
      path: '',
      query: {},
      params: {},
      panel: false,
      search: false
    } as any),
    navigator: ref({
      language: '',
      country: ''
    }),
    resolution: ref(globalVariables.resolution),
    alert: ref({
      sync: false,
      title: '',
      contents: '',
      button: ''
    }),
    confirm: ref({
      sync: false,
      title: '',
      contents: '',
      button: '',
      callback: null
    }),
    preview: ref({
      sync: false,
      url: ''
    }),
    popstate: ref({
      available: false,
      back: '',
      current: '',
      forward: '',
      position: 0,
      replaced: 0,
      timestamp: 0,
      scroll: {
        top: ref(0),
        left: ref(0)
      },
      history: '',
      first: true // 한번이라도 라우터를 사용하면 false로 변환
    }),
    advertise: ref({
      sync: false
    })
  }),
  actions: {
    setClient () {
      this.isClient = true
    },
    setError (value: boolean, msg: string = '') {
      this.error = value
      this.errorMsg = msg
    },
    setLoader (value: boolean) {
      this.loader = value
    },
    setRouterLoader (value: boolean) {
      this.loaderRouter = value
    },
    setRouterPage (value: boolean) {
      this.loaderPage = value
    },
    setLayout (type: string, value: any) {
      this.layout[type] = value
    },
    setPopstate (value: any) {
      const popstate: any = {
        available: true,
        back: value.back || '',
        current: value.current || '',
        forward: value.forward || '',
        position: value.position || 0,
        replaced: value.replaced || 0,
        timestamp: new Date().getTime(),
        scroll: {
          top: value.scroll?.top || 0,
          left: value.scroll?.left || 0
        },
        history: this.popstate.history,
        first: false
      }
      this.popstate = popstate
    },
    setPopstateTimestamp () {
      this.popstate.timestamp = new Date().getTime()
    },
    setPopstateUnavailable (move: boolean = false) {
      this.popstate.available = false
      this.popstate.timestamp = new Date().getTime()
      if (move) {
        this.popstate.first = false
      }
    },
    setPopstateHistory () {
      const route = useRoute()
      this.popstate.history = route.fullPath
    },
    setUserAgent () {
      const userAgent = useCookie('userAgent')
      const userAgentData: any = typeof userAgent.value === 'object' ? userAgent.value : null
      if (userAgentData) {
        this.navigator.country = userAgentData.country
        this.navigator.language = userAgentData.language
      } else {
        // 허용한 국가에 속하지 않으면 기본 언어, 국가로 설정한다.
        const userNavigator = process.client ? navigator.language || globalVariables.userAgent : globalVariables.userAgent
        const languageCode: any = userNavigator?.split('-')[0]?.toLowerCase()
        const countryCode: any = userNavigator?.split('-')[1]?.toUpperCase()
        const isLanguageCode = globalVariables.language.filter(language => { language.code === languageCode })[0]
        const isCountryCode = globalVariables.country.filter(country => { country.code === countryCode })[0]        
        const $i18n: any = globalVariables.app.i18n
        if (isLanguageCode) {
          this.navigator.language = isLanguageCode.code
          $i18n.$changei18n(isLanguageCode.code)
        } else {
          this.navigator.language = languageCode
          $i18n.$changei18n(languageCode)
        }
        if (isCountryCode) {
          this.navigator.country = isCountryCode.code
        } else {
          this.navigator.country = countryCode
        }
        userAgent.value = JSON.stringify(this.navigator)
      }
    },
    setResolution (w: number, h: number) {
      let font: number = 0
      if (w < globalVariables.resolution.min) {
        font = globalVariables.resolution.fonts[4]
      } else if (w < globalVariables.resolution.mobile) {
        font = globalVariables.resolution.fonts[3]
      } else if (w < globalVariables.resolution.tablet) {
        font = globalVariables.resolution.fonts[2]
      } else if (w < globalVariables.resolution.notebook) {
        font = globalVariables.resolution.fonts[1]
      } else {
        font = globalVariables.resolution.fonts[0]
      }
      const resolution: any = {
        ...this.resolution,
        width: w,
        height: h,
        font: font
      }
      this.resolution = resolution
    },
    setResolutionScroll (scroll: number = 0) {
      const resolution: any = {
        ...this.resolution,
        scroll: scroll
      }
      this.resolution = resolution
    },
    resetAlert () {
      const alert = {
        sync: false,
        title: '',
        contents: '',
        button: ''
      }
      this.alert = alert
    },
    toggleAlert (title: string = '', contents: string = '', button: string = '') {
      const alert: any = {
        sync: !this.alert.sync,
        title: title || this.alert.title,
        contents: contents || this.alert.contents,
        button: button || this.alert.button
      }
      this.alert = alert
    },
    toggleConfirm (title: string = '', contents: string = '', button: string = '', callback: any = {}) {
      const confirm: any = {
        sync: !this.confirm.sync,
        title: title || this.confirm.title,
        contents: contents || this.confirm.contents,
        button: button || this.confirm.button,
        callback: callback || this.confirm.button
      }
      this.confirm = confirm
    },
    togglePreview (url: string = '') {
      const preview: any = {
        sync: !this.preview.sync,
        url: url || this.preview.url
      }
      this.preview = preview
    },
    setAdvertiseSync (value: boolean = false) {
      this.advertise.sync = value
    }
  }
})
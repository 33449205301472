import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { APICALL } from '~/utils/api'

// 전역 토큰
export const globalTokens = {

  // 토큰 조회 > 발급까지 원스탭
  async isToken () {

    // 쿠키 조회
    const token: any = useCookie('token')

    // 토큰 있음
    if (token.value) {
      // 리프레시 토큰 만료
      if (globals.isExpire(token.value.refresh.expire)) {
        if (globals.isDev()) {
          console.log(`%cTOKEN - REFRESH 만료`, 'color: white; background: red; padding: 5px 10px; font-weight: bold;')
        }
        await this.getRefreshToken()
      // 엑세스 토큰 만료
      } else if (globals.isExpire(token.value.access.expire)) {
        if (globals.isDev()) {
          console.log(`%cTOKEN - ACCESS 만료`, 'color: red; background: black; padding: 5px 10px; font-weight: bold;')
        }
        await this.getAccessToken()
      }

    // 토큰 없음
    } else {
      await this.getToken()
    }
  },

  // 첫 토큰 발행
  async getToken () {

    // API 정의 -----------------------------------------------------------------------------
    let api: any = {}
    api.params = {}
    api.payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
    api.address = `/token/get-token`
    api.DATA = await APICALL(api.address, api.payload, api.params, false, false, true)
    // --------------------------------------------------------------------------------------

    if (api.DATA.result?.success) {
      if (globals.isDev()) {
        console.log(`%cTOKEN - 신규 발행 완료`, 'color: white; background: red; padding: 5px 10px; font-weight: bold;')
        console.log(`└ 서버 전용 토큰 변수 기록 : ${api.DATA.result.data.access.token}`)
      }
      globalVariables.token.server = api.DATA.result.data.access.token
      const authStore = useAuthStore()
      authStore.updateToken({
        access: {
          token: process.client ? globals.encrypt(api.DATA.result.data.access.token) : globals.nuxtEncrypt(api.DATA.result.data.access.token),
          expire: globals.expire(api.DATA.result.data.access.expire)
        },
        refresh: {
          token: process.client ? globals.encrypt(api.DATA.result.data.refresh.token) : globals.nuxtEncrypt(api.DATA.result.data.refresh.token),
          expire: globals.expire(api.DATA.result.data.refresh.expire)
        }
      })
    } else if (!api.DATA.result?.success && api.DATA.result?.error) {
      if (globals.isDev()) {
        console.log(`%cTOKEN - 신규 발행 실패`, 'color: red; background: black; padding: 5px 10px; font-weight: bold;')
      }
      globalFunctions.pageError(true)
    } else {
      if (globals.isDev()) {
        console.log(`%cTOKEN - 신규 발행 실패 ( 예외 )`, 'color: red; background: black; padding: 5px 10px; font-weight: bold;')
      }
      globalFunctions.pageError(true)
    }

    // 메모리 제거
    api = {}
  },

  // 리프레시 토큰 발행
  async getRefreshToken () {

    // API 정의 -----------------------------------------------------------------------------
    let api: any = {}
    api.params = {}
    api.payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
    api.address = `/token/get-token`
    api.DATA = await APICALL(api.address, api.payload, api.params, false, false, true)
    // --------------------------------------------------------------------------------------

    // 결과
    if (api.DATA.result?.success) {
      if (globals.isDev()) {
        console.log(`%cTOKEN - ACCESS + REFRESH 발행 완료`, 'color: white; background: red; padding: 5px 10px; font-weight: bold;')
      }
      const authStore = useAuthStore()
      authStore.updateToken({
        access: {
          token: globals.encrypt(api.DATA.result.data.access.token),
          expire: globals.expire(api.DATA.result.data.access.expire)
        },
        refresh: {
          token: globals.encrypt(api.DATA.result.data.refresh.token),
          expire: globals.expire(api.DATA.result.data.refresh.expire)
        }
      })
    } else if (!api.DATA.result?.success && api.DATA.result?.error) {
      if (globals.isDev()) {
        console.log(`%cTOKEN - ACCESS + REFRESH 발행`, 'color: red; background: black; padding: 5px 10px; font-weight: bold;')
      }
      globalFunctions.pageError(true)
    } else {
      if (globals.isDev()) {
        console.log(`%cTOKEN - ACCESS + REFRESH 발행 ( 예외 )`, 'color: red; background: black; padding: 5px 10px; font-weight: bold;')
      }
      globalFunctions.pageError(true)
    }

    // 메모리 제거
    api = {}
  },

  // 엑세스 토큰 발행
  async getAccessToken () {

    // 리프레시 토큰 없으면 로그아웃
    const { user } = storeToRefs(useAuthStore())
    if (user.value.login) {
      globalFunctions.logOut()
      return
    }

    // API 정의 -----------------------------------------------------------------------------
    let api: any = {}
    api.params = {}
    api.payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${globalStores.getRefreshToken()}`
      }
    }
    api.address = `/token/get-token`
    api.DATA = await APICALL(api.address, api.payload, api.params, false, false, false)
    if (api.DATA?.result?.error?.code === 'expired_token') {
      await globalTokens.isToken()
      api.payload.headers.Authorization = `Bearer ${globalStores.getRefreshToken()}`
      api.DATA = await APICALL(api.address, api.payload, api.params, false, false, false)
    } // -----------------------------------------------------------------------------------

    // 결과
    if (api.DATA.result?.success) {
      if (globals.isDev()) {
        console.log(`%cTOKEN - ACCESS 발행 완료`, 'color: white; background: red; padding: 5px 10px; font-weight: bold;')
      }
      const authStore = useAuthStore()
      authStore.updateToken({
        access: {
          token: globals.encrypt(api.DATA.result.data.access.token),
          expire: globals.expire(api.DATA.result.data.access.expire)
        }
      })
    } else if (!api.DATA.result?.success && api.DATA.result?.error) {
      if (globals.isDev()) {
        console.log(`%cTOKEN - ACCESS 발행 실패`, 'color: red; background: black; padding: 5px 10px; font-weight: bold;')
      }
      globalFunctions.pageError(true)
    } else {
      if (globals.isDev()) {
        console.log(`%cTOKEN - ACCESS 발행 실패 ( 예외 )`, 'color: red; background: black; padding: 5px 10px; font-weight: bold;')
      }
      globalFunctions.pageError(true)
    }

    // 메모리 제거
    api = {}
  }
}
import type { User } from "firebase/auth"
import {
  getAuth,
} from "firebase/auth"

export function useAuth() {
  const auth = getAuth()
  const user = ref<User | null>(auth.currentUser)
  auth.onIdTokenChanged((authUser) => (user.value = authUser))

  return { auth, user }
}
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'

export default defineNuxtPlugin(async (nuxtApp: any) => {

  // https://github.com/nuxt/nuxt/issues/13350
  let previousTime = 0
  let currentTime = 0
  let intervalTime = 100

  const { loaderPage, loader } = storeToRefs(useGlobalStore());
  const globalStore = useGlobalStore();

  if (process.client) {
    nuxtApp.$router.beforeEach(async (to: any, from: any, next: any) => {
      const currentTime = Date.now()

      // 로딩 중이 아닐 때 라우터 시작
      if (currentTime - previousTime > intervalTime && (!loaderPage.value && !loader.value)) {
        globalFunctions.isRouter(true)    // 라우터가 시작됐음을 알리고
        globalFunctions.isUser()          // 현재 사용자 정보를 싱크한다
        await globalTokens.isToken()      // 토큰이 있는지 확인하자. 안해도 되는건가?
        next(true)
      } else {
        next(false)
      }
    })
  }

  nuxtApp.hook('vue:setup', () => {
    // 사용자 업데이트 (정보만 업데이트)
    globalFunctions.isUser()

    // 팝스테이트 업데이트
    globalStore.setPopstateUnavailable()

    // 언어 업데이트
    globalStore.setUserAgent()

    // 현재 시간 업데이트
    currentTime = 0
  })

  nuxtApp.hook('app:beforeMount', () => {
    // 행동 업데이트
    const authStore = useAuthStore()
    authStore.syncExperience()
  })

  nuxtApp.hook('page:start', () => {
    // 팝스테이트 히스토리 변경
    globalStore.setPopstateHistory()

    // 페이지 라우터 시작
    globalStore.setRouterPage(true)
  })

  nuxtApp.hook('page:finish', () => {
    // 라우터 로더 종료
    globalFunctions.isRouter(false)

    // 페이지 이동 종료시 필수 수행 ------------------------------------------
    if (window) {
      const { popstate } = storeToRefs(useGlobalStore())
      if (!popstate.value.available) {
        window.scrollTo(0, 0)

        // 의미없는 돔 제거
        let arr = []
        arr.push(...document.querySelectorAll('.v-btn .v-btn__overlay'))
        arr.push(...document.querySelectorAll('.v-btn .v-btn__underlay'))
        arr.forEach(element => {
          element.remove()
        })

        //
        previousTime = 0
        arr = []
      }
    }

    // 페이지 라우터 종료
    globalStore.setRouterPage(false)
  })

  nuxtApp.hook('page:transition:finish', () => {
    console.log('page:transition:finish -------------------------')
    previousTime = Date.now()
  })
})

// export default defineNuxtPlugin(async (NuxtApp) => {
//   name: 'router',
//   hooks: {
//     'vue:setup': function () {

//       // 라우터 설정
//       let nuxtApp = useNuxtApp()

//       // 전역으로 기억해주자
//       globalVariables.app = {
//         i18n: nuxtApp.vueApp.config.globalProperties,
//         config: nuxtApp.$config
//       }

//       // 사용자 업데이트
//       globalFunctions.isUser()

//       // 국가, 팝스테이트 업데이트
//       const { loaderRouter, loaderPage, loader } = storeToRefs(useGlobalStore())
//       let globalStore = useGlobalStore()
//       globalStore.setPopstateUnavailable()
//       globalStore.setUserAgent()

//       if (process.client) {
//         nuxtApp.$router.beforeEach( async (to: any, from: any, next: any) => {
//           currentTime = Date.now()
//           if (currentTime - previousTime > intervalTime && (!loaderPage.value && !loader.value)) {
//             globalFunctions.isRouter(true)
//             globalFunctions.isUser()
//             await globalTokens.isToken()
//             next(true)
//           } else {
//             next(false)
//           }
//         })
//       }

//       //
//       currentTime = 0

//     },
//     'app:created': function () {
//     },
//     'app:beforeMount': function () {
//       // 행동 업데이트
//       const authStore = useAuthStore()
//       authStore.syncExperience()
//     },
//     'app:mounted': function () {
//       //
//     },
//     'page:start': function () {      
//       // 마지막 라우터를 기록
//       const globalStore = useGlobalStore()
//       globalStore.setPopstateHistory()
//       globalStore.setRouterPage(true)
//     },
//     'page:finish': function (nuxtApp: any) {
//       previousTime = Date.now()

//       // 라우터 로더 종료
//       globalFunctions.isRouter(false)

//       // 페이지 이동 종료시 필수 수행 ------------------------------------------
//       if (window) {
//         const { popstate } = storeToRefs(useGlobalStore())
//         if (!popstate.value.available) {
//           window.scrollTo(0, 0)

//           // 의미없는 돔 제거
//           let arr = []
//           arr.push(...document.querySelectorAll('.v-btn .v-btn__overlay'))
//           arr.push(...document.querySelectorAll('.v-btn .v-btn__underlay'))
//           arr.forEach(element => {
//             element.remove()
//           })

//           //
//           previousTime = 0
//           arr = []
//         }
//       }

//       // 마지막 라우터를 기록
//       const globalStore = useGlobalStore()
//       globalStore.setRouterPage(false)

//     },
//     'page:transition:finish': function () {
//       previousTime = Date.now()
//     }
//   }
// })
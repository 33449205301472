import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

/*
** 페이징 전용 함수
** 검색, 앞으로, 뒤로
*/
export const paginationFunction = {

  /*
  ** 페이지 변수 기본 셋팅
  ** params 는 사용할 파라미터를 배열로 할당
  */
  setPagination (route: any = {}, key: string = '', path: string = '', params: any = [], size: string = globalVariables.pagination.colsize) {
    const pagination = globalFunctions.deepCopy(globalVariables.pagination)
    pagination.path = path
    pagination.page = Number(route.query.page?.toString()) || 1
    pagination.key = globalVariables.popstate.key + key
    pagination.size = size
    params.forEach((param: any) => {
      pagination[param] = route.query[param]?.toString() || undefined
    })
    return pagination
  },

  /*
  ** 검색 인터발
  ** 앞, 뒤, 검색, 페이징 모두 적용
  */
  searchInterval (hook: string = '', pagination: any = {}, query: any = {}) {
    if (globals.isDev()) {
      console.log(`%c-------- ${hook} --------`, 'color: skyblue;')
    }
    clearTimeout(pagination.delayTimeout)
    pagination.delayTimeout = setTimeout(() => {
      pagination.page = !isNaN(query.page) ? Number(query.page) : query.page
      pagination.search = Math.floor(Math.random() * 1000000)
    }, pagination.timeout)
  },

  /*
  ** 라우트 변화 감지
  ** 페이지, 키워드만 체크했다.
  ** 만약 다른 파라미터가 붙는다면 조건이 추가되어야 한다.
  */
  watchRoute (pagination: any = {}, from: any = {}, to: any = {}) {
    if (globals.isDev()) {
      console.log(`%c-------- route --------`, 'color: skyblue;')
    }
    const { popstate } = storeToRefs(useGlobalStore())
    if (to.fullPath === pagination.path) {
      this.searchInterval('route - 1', pagination, { page: 1 })
    } else if (!to.query.page && to.query.keyword != from.query.keyword) {
      this.searchInterval('route - 2', pagination, { page: 1, keyword: to.query.keyword })
    } else if (!popstate.value.available && !pagination.busy && pagination.search && this.deepQueryCompare(to, from)) {
      this.searchInterval('route - 3', pagination, to.query)
    } else if (!popstate.value.available && !pagination.busy && (to.query.page === from.query.page && to.query.keyword != from.query.keyword)) {
      this.searchInterval('route - 4', pagination, to.query)
    }
  },

  /*
  ** 실제 검색을 실행한다.
  ** 모든 WATCH 요소가 이쪽을 본다.
  */
  watchSearch (pagination: any = {}, route: any = {}) {
    const { popstate } = storeToRefs(useGlobalStore())

    // console.log(popstate.value.available, '-- popstate.value.available --')
    // console.log(JSON.stringify(pagination.params), '-- pagination.params --')
    // console.log(JSON.stringify(route.query), '-- route.query --')
    // console.log(JSON.stringify(pagination.page), `-- pagination.page --`)
    // console.log(JSON.stringify(pagination.keyword), `-- pagination.keyword --`)
    // console.log(this.checkPageParameter(pagination), `-- this.checkPageParameter(pagination) --`)
    // console.log(this.deepAllSameCompare(pagination.params, route.query ,'page'), `-- this.deepAllSameCompare(pagination.params, route.query ,'page') --`)
    // console.log(this.deepAllSameCompare(pagination.params, route.query ,''), `-- this.deepAllSameCompare(pagination.params, route.query ,'') --`)
    // console.log(this.checkPageTrigger(pagination, route), `-- this.checkPageTrigger(pagination, route) --`)

    // 페이지를 클릭한 시간이 1초 이내라면 강제로 통과시켜준다.
    const nowDate = new Date().getTime()
    const pageDelay = pagination.pagestamp ? nowDate - pagination.pagestamp : pagination.timeout * 2
    const apiDelay = pagination.apistamp ? nowDate - pagination.apistamp : pagination.timeout * 2

    // 현재, 이전페이지 체크
    const currentFullpath = popstate.value.current.toString()
    const lastFullpath = popstate.value.history.toString()

    // console.log('여기 왔는데 통과라고??')
    // console.log(nowDate, '==nowDate')
    // console.log(apiDelay, '==apiDelay')
    // console.log(pagination.apistamp, '==pagination.apistamp')
    // console.log(pageDelay, '==pageDelay')
    // console.log(pagination.pagestamp, '==pagination.pagestamp')
    // console.log(pagination.timeout, '==pagination.timeout')
    // console.log(currentFullpath, '==currentFullpath')
    // console.log(lastFullpath, '==lastFullpath')

    // API 시도 스탬프 기록
    pagination.apistamp = new Date().getTime()

    if (apiDelay < pagination.timeout * 2) {
      if (globals.isDev()) {
        console.log(`%c-------- STOP WATCH - BAN API DELAY (${apiDelay}) --------`, 'color: red;')
      }
      return false
    } else if (pageDelay < pagination.timeout * 2) {
      if (globals.isDev()) {
        console.log(`%c-------- STOP WATCH - CONTINUE (${pageDelay}) --------`, 'color: purple;')
      }
      return true
    } else if (!pagination.page && !pagination.keyword) {
      if (globals.isDev()) {
        console.log(`%c-------- STOP WATCH A --------`, 'color: purple;')
      }
      return false
    } else if (this.checkPageParameter(pagination) && this.deepAllSameCompare(pagination.params, route.query ,'page') && currentFullpath === lastFullpath) {
      if (globals.isDev()) {
        console.log(`%c-------- STOP WATCH B --------`, 'color: purple;')
      }
      return false
    } else if (this.checkPageTrigger(pagination, route) && this.deepAllSameCompare(pagination.params, route.query ,'')) {
      if (globals.isDev()) {
        console.log(`%c-------- STOP WATCH C --------`, 'color: purple;')
      }
      return false
    } else {
      return true
    }
  },

  /*
  ** 첫페이지 파라미터 리턴
  ** page 1을 고려한 함수
  */
  getFirstPageParams (params: any = {}) {
    const route = useRoute()
    if (route.query.page || params.page > 1) {
      return params
    } else {
      delete params.page
      return params
    }
  },

  /*
  ** 스토리지 데이터 기록
  ** 오브젝트 형태
  */
  setStorageData (key: string = '', data: Object = {}) {
    if (globals.isDev()) {
      console.log(`%c-------- SET : ${key} --------`, 'color: orange;')
    }
    try {
      const jsonString = JSON.stringify(data)
      localStorage.setItem(key, jsonString)
    } catch (error) {
      if (globals.isDev()) {
        console.log(data, `== ${key} 스토리지 데이터 굽기 실패 ==`)
      }
    }
  },

  /*
  ** 스토리지 랜더링이 가능한 상태인지 체크
  ** 예외처리를 하기 위해 한곳에 묶었다.
  */
  storageRenderingAvailable (key: string = '') {
    const { popstate } = storeToRefs(useGlobalStore())
    const availData: any = localStorage.getItem(key) || {}
    const storageLength = Object.keys(JSON.parse(availData)).length
    return Boolean(popstate.value.available && storageLength)
  },

  /*
  ** 스토리지 랜더링
  ** JSON 데이터만 기본 분석
  */
  getStorageRendering (key: string = '', values: any = []) {

    // 클라이언트에서만 실행한다.
    if (!process.client) {
      return null
    }

    const route = useRoute()
    const { popstate } = storeToRefs(useGlobalStore())
    const data: any = localStorage.getItem(key) || {}

      // console.log(JSON.stringify(popstate.value), '== JSON.stringify(popstate.value) ==')
      // console.log(popstate.value.current, '== popstate.value.current ==')
      // console.log(popstate.value.forward, '== popstate.value.forward ==')
      // console.log(popstate.value.forward.split('?')[0], '== popstate.value.forward.split(`?`)[0] ==')
      // console.log(popstate.value.history, '== popstate.value.history ==')
      // console.log(route.fullPath, '== route.fullPath ==')
      // console.log(route.path, '== route.path ==')
      // console.log(popstate.value, '== popstate ==')

    // 다른 페이지에서의 진입만 인정한다.
    if ((!popstate.value.forward && !popstate.value.history) || popstate.value.forward.split('?')[0] === route.path) {
      return null
    }

    try {
      const jsonData = JSON.parse(data)
      let valueCounts = 0
      values.forEach((value: any) => {
        if (jsonData[value]) {
          valueCounts++
        }
      })
      // console.log(popstate.value.available, '== popstate.value.available ==')
      // console.log(valueCounts, '== valueCounts ==')
      // console.log(values.length, '== values.length ==')
      // console.log(jsonData, '== jsonData ==')
      // console.log(JSON.stringify(jsonData), '== JSON.stringify(jsonData) ==')
      if (popstate.value.available && valueCounts === values.length) {
        if (globals.isDev()) {
          console.log(`%c-------- GET STORAGE DATA : ${key} --------`, 'color: orange;')
        }
        return jsonData
      } else {
        return null
      }
    } catch (error) {
      console.log('--에러--')
      return null
    }
  },

  /*
  ** 검색 인터발
  ** 앞, 뒤, 검색, 페이징 모두 적용
  */
  routeInterval (to: any = {}, from: any = {}, pagination: any = {}, callback: (result: boolean) => void) {
    // console.log(`%c-------- routeInterval --------`, 'color: skyblue;')
    // console.log(to.fullPath, '== to.fullPath ==')
    // console.log(from.fullPath, '== from.fullPath ==')
    // console.log(pagination.path, '== pagination.path ==')
    // console.log(to.path, '== to.path ==')
    // console.log(globalFunctions.normalizeUrl(to.fullPath), '== normalizeUrl : to.fullPath ==')
    // console.log(globalFunctions.normalizeUrl(from.fullPath), '== normalizeUrl : from.fullPath ==')
    // console.log(globalFunctions.normalizeUrl(pagination.path), '== normalizeUrl : pagination.path ==')
    // console.log(globalFunctions.normalizeUrl(to.path), '== normalizeUrl : to.path ==')   
    if (globalFunctions.normalizeUrl(to.fullPath) !== globalFunctions.normalizeUrl(from.fullPath) && globalFunctions.normalizeUrl(pagination.path) === globalFunctions.normalizeUrl(to.path)) {
      clearTimeout(pagination.delayTimeout)
      pagination.delayTimeout = setTimeout(() => {
        callback(true)
      }, pagination.timeout)
    }
  },
  watchPaginationPage (to: number = 0) {
    const route = useRoute()
    const router = useRouter()
    const { loader } = storeToRefs(useGlobalStore())
    if (!loader.value && (route.query.page || to > 1)) {
      router.push({
        path: route.path,
        query: {
          ...route.query,
          page: to
        }
      })
    }
  },

  /*
  ** 스토리지 랜더링
  ** JSON 데이터만 기본 분석
  */
  storageRendering (key: string = '') {
    if (globals.isDev()) {
      console.log(`%c-------- RENDERING : ${key} --------`, 'color: orange;')
    }
    const data = localStorage.getItem(key)
    try {
      return data ? JSON.parse(data) : null
    } catch (error) {
      return null
    }
  },

  /*
  ** 오브젝트 내부에 값이 다른게 하나라도 있는지 체크
  ** 쿼리스트링에 따라 검색을 할 결정하기 위함
  */
  deepQueryCompare (obj1: any = {}, obj2: any = {}) {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj1 === obj2
    }
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
  
    // 키의 개수가 다르면 일치하지 않음
    if (keys1.length !== keys2.length) {
      return false
    }
    for (const key of keys1) {
      if (!obj2.hasOwnProperty(key)) {
        return false
      }
    }
    return true
  },

  /*
  ** 오브젝트 속 모든 값이 같은지 체크
  ** 쿼리스트링에 따라 검색을 종료할지 결정하기 위함
  */
  deepAllSameCompare (obj1: any = {}, obj2: any = {}, exception: string = '') {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    if (!keys1.length && !keys2.length) {
      return false
    }
    for (const key of keys1.length > keys2.length ? keys1 : keys2) {
      if (key === 'error') {
        continue
      }
      if (exception && key === exception) {
        continue
      }
      if (obj1[key]?.toString() !== obj2[key]?.toString()) {
        return false
      }
    }
    return true
  },

  /*
  ** 페이지 여부만 비교하기
  ** 있거나 없는 경우 모두 비교
  */
  checkPageParameter (pagination: any = {}) {
    if (pagination.params?.page) {
      return Boolean(pagination.params.page === pagination.page)
    } else if (!pagination.params && !pagination.page) {
      return false
    } else {
      return false
    }
  },

  /*
  ** 클릭으로 이동하는 페이지를 처리한다.
  ** 검색 혹은 기타 행위 후 페이징을 할 때 동작
  */
  checkPageTrigger (pagination: any = {}, route: any = {}) {
    return Boolean(pagination.page === 1 || (pagination.page === pagination.params?.page === route.query?.page))
  },

  /*
  ** ONLIST 시점에 페이지 정의
  ** 호출된 새로운 API 데이터를 가공한다.
  */
  updatePagination (APIDATA: any = {}, pagination: any = {}, page: number = 1) {
    pagination.page = Number(page)
    pagination.total = APIDATA?.result?.data?.total || 0
    pagination.total = isNaN(pagination.total) ? 0 : Number(pagination.total)
    pagination.count = Math.ceil(pagination.total / pagination.size) || 0
  },

  /*
  ** 라우터 업데이트
  ** ONLIST 에서 발동한다.
  */
  updateRouter (pagination: any = {}, page: number = 1) {
    const route = useRoute()
    const router = useRouter()
    if (page === 1 && route.fullPath === pagination.path) {
      // console.log(pagination.path, 1)
      router.push({
        path: pagination.path
      })
    } else {
      // console.log(route.fullPath, 2)
      router.push({
        path: route.fullPath,
        query: this.getFirstPageParams(pagination.params)
      })
    }
  },

  /*
  ** API ONLIST 처리 완료
  ** 데이터를 초기화 하고 스크롤 이동
  */
  endOfOnlist (pagination: any = {}) {
    const { popstate } = storeToRefs(useGlobalStore())
    pagination.busy = false
    pagination.search = null
    if (popstate.value.available) {
      globalFunctions.scrollToTop(popstate.value.scroll.top, popstate.value.scroll.left)
    } else {
      globalFunctions.scrollToTop()
    }
  },

  /*
  ** 1페이지에서 에러를 만나는 경우 체크
  */
  isErrorFirstpage (to: any = {}, error: boolean = false) {
    if (to.query.error && !error && (!to.query.page || to.query.page === 1)) {
      return true
    } else {
      return  false
    }
  }
}
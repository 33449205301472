import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'

// 전역 스토어
export const globalStores = {

  // 글로벌 스토어 가져오기
  getGlobal () {
    const globalStore = useGlobalStore()
    return globalStore
  },

  // 클라이언트 여부 가져오기
  getIsClient () {
    const { isClient } = storeToRefs(useGlobalStore())
    return isClient
  },
  
  // 엑세스 토큰 가져오기
  getAccessToken () {
    const { token } = storeToRefs(useAuthStore())
    if (process.server && !token.value.access.token) {
      if (globals.isDev()) {
        console.log(`└ 서버 혹은 가장 최근 토큰 반환 : ${globalVariables.token.server}`)
      }
      return globalVariables.token.server
    } else {
      return globals.decrypt(token.value.access.token)
    }
  },

  // 엑세스 토큰 가져오기
  getRefreshToken () {
    const { token } = storeToRefs(useAuthStore())
    return globals.decrypt(token.value.refresh.token)
  },

  // 회원가입 인증 정보 가져오기
  getJoin () {
    const { join } = storeToRefs(useAuthStore())
    return join
  },

  // 회원가입 함수 가져오기
  getAuthenticateJoin () {
    const { authenticateJoin } = useAuthStore()
    return authenticateJoin
  },

  // 계정찾기 정보 가져오기
  getFind () {
    const { find } = storeToRefs(useAuthStore())
    return find
  },

  // 계정찾기 함수 가져오기
  getAuthenticateFind () {
    const { authenticateFind } = useAuthStore()
    return authenticateFind
  },

  // 로그인 함수 가져오기
  getAuthenticateUser () {
    const { authenticateUser } = useAuthStore()
    return authenticateUser
  },

  // 회원정보 변경 함수 가져오기
  getUpdateProfile () {
    const { updateProfile } = useAuthStore()
    return updateProfile
  },

  // 회원정보 가져오기
  getUser () {
    const { user } = storeToRefs(useAuthStore())
    return user
  },

  // 경험 가져오기
  getExperience () {
    if (process.client) {
      const { experience } = storeToRefs(useAuthStore())
      return experience
    }
  },

  // 히스토리 가져오기 ( 이전페이지 )
  getHistory () {
    const { history } = storeToRefs(useAuthStore())
    return history
  },

  // 토큰 스토어 업데이트
  updateTokenStore () {
    if (process.client) {
      const authStore = useAuthStore()
      authStore.updateToken({})
    }
  },
  
  // 에러 가져오기
  getError () {
    const { error } = storeToRefs(useGlobalStore())
    return error
  },

  // 에러 메세지 가져오기
  getErrorMsg () {
    const { errorMsg } = storeToRefs(useGlobalStore())
    return errorMsg
  },

  // 레이아웃 가져오기
  getLayout () {
    const { layout } = storeToRefs(useGlobalStore())
    return layout
  },

  // 팝스테이트 가져오기
  getPopstate () {
    const { popstate } = storeToRefs(useGlobalStore())
    return popstate
  },

  // 언어 가져오기
  getLanguage () {
    const { navigator } = storeToRefs(useGlobalStore())
    return navigator.value.language
  },

  // 국가 가져오기
  getCountry () {
    const { navigator } = storeToRefs(useGlobalStore())
    return navigator.value.country
  },

  // 리솔루션 가져오기
  getResolution () {
    const { resolution } = storeToRefs(useGlobalStore())
    return resolution
  },

  // 로더 가져오기
  getLoader () {
    const { loader } = storeToRefs(useGlobalStore())
    return loader
  },

  // 라우터 가져오기
  getLoaderRouter () {
    const { loaderRouter } = storeToRefs(useGlobalStore())
    return loaderRouter
  },

  // 경고창 가져오기
  getAlert () {
    const { alert } = storeToRefs(useGlobalStore())
    return alert
  },

  // 컨펌창 가져오기
  getConfirm () {
    const { confirm } = storeToRefs(useGlobalStore())
    return confirm
  },

  // 미리보기 가져오기
  getPreview () {
    const { preview } = storeToRefs(useGlobalStore())
    return preview
  },

  // 광고 싱크 가져오기
  getAdvertiseSync () {
    const { advertise } = storeToRefs(useGlobalStore())
    return advertise
  }

}
import { globals } from '~/utils/default'
import { globalVariables } from '~/utils/variables'
import { globalFunctions } from '~/utils/functions'
import { globalMessages } from '~/utils/messages'
import { globalTokens } from '~/utils/tokens'
import { globalStores } from '~/utils/stores'
import { globalAdvertise } from '~/utils/advertise'
import { paginationFunction } from '~/utils/pagination'

// 타입 선언
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $globals: typeof globals
    $globalVariables: typeof globalVariables
    $globalFunctions: typeof globalFunctions
    $globalMessages: typeof globalMessages
    $globalTokens: typeof globalTokens
    $globalStores: typeof globalStores
    $globalAdvertise: typeof globalAdvertise
    $paginationFunction: typeof paginationFunction
  }
}

// 전역 설정
export default defineNuxtPlugin(nuxtApp => {

  // 기본
  nuxtApp.vueApp.config.globalProperties.$globals = globals
  
  // 변수
  nuxtApp.vueApp.config.globalProperties.$globalVariables = globalVariables
  
  // 함수
  nuxtApp.vueApp.config.globalProperties.$globalFunctions = globalFunctions
  
  // 메세지
  nuxtApp.vueApp.config.globalProperties.$globalMessages = globalMessages
  
  // 토큰
  nuxtApp.vueApp.config.globalProperties.$globalTokens = globalTokens
  
  // 스토어
  nuxtApp.vueApp.config.globalProperties.$globalStores = globalStores
  
  // 광고
  nuxtApp.vueApp.config.globalProperties.$globalAdvertise = globalAdvertise
  
  // 페이지
  nuxtApp.vueApp.config.globalProperties.$paginationFunction = paginationFunction

})
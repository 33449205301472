import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

export default defineNuxtPlugin((NuxtApp) => {
  const cookie = useCookie('theme')
  const vuetify = createVuetify({
    ssr: true,
    theme: {
      // defaultTheme: cookie.value || 'dark',
      defaultTheme: 'dark' || cookie.value,
      themes: {
        dark: {
          dark: true,
          variables: {}, // ✅ this property is required to avoid Vuetify crash
          colors: {
            //green: '#00ff00' // cannot use primary color names here, so use a custom color name (such as 'greenish')
            greenish: '#03DAC5',
            // Workaround: Custom colors seem to erase default colors, so we need to include the default colors (of `light` or `dark` theme)
            background: '#fbfbfb',
            surface: '#212121',
            primary: '#e13a32', // 이거랑
            'primary-darken-1': '#3700B3',
            secondary: '#439bbb', // 이거만
            'secondary-darken-1': '#03DAC5',
            error: '#CF6679',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FB8C00'
          }
        }
      }
    },
    components,
    //directives
  })

  // 뷰티파이 적용
  NuxtApp.vueApp.use(vuetify)

  // 쿠키에 따라 초기 테마를 결정
  NuxtApp.vueApp.mixin({
    mounted() {
      const cookie = useCookie('theme')
      const html = document.querySelector('html')
      html?.classList.remove('dark', 'light')
      html?.classList.add(cookie.value || 'dark')
    }
  })
})
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from 'firebase/firestore'
import { getAnalytics, isSupported } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default defineNuxtPlugin( async (NuxtApp) => {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: globals.decrypt(globalVariables.app.config.public.FIREBASE_API_KEY),
    authDomain: globals.decrypt(globalVariables.app.config.public.FIREBASE_AUTH_DOMAIN),
    projectId: globals.decrypt(globalVariables.app.config.public.FIREBASE_PROJECT_ID),
    storageBucket: globals.decrypt(globalVariables.app.config.public.FIREBASE_STORAGE_BUCKET),
    messagingSenderId: globals.decrypt(globalVariables.app.config.public.FIREBASE_MESSAGING_SENDER_ID),
    appId: globals.decrypt(globalVariables.app.config.public.FIREBASE_APP_ID),
    measurementId: globals.decrypt(globalVariables.app.config.public.FIREBASE_MEASUREMENT_ID)
  }

  // Initialize Firebase
  if (await isSupported()) {
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)
    const firestore = getFirestore(app)
    const { auth, user } = useAuth()

    NuxtApp.vueApp.provide('firebase_app', app)
    NuxtApp.vueApp.provide('firebase_auth', auth)
    NuxtApp.vueApp.provide('firebase_user', user)
    NuxtApp.vueApp.provide('firebase_analytics', analytics)
    NuxtApp.vueApp.provide('firebase_firestore', firestore)

    globalVariables.firebase.auth = auth
    globalVariables.firebase.user = user
    globalVariables.firebase.analytics = analytics
  }
})